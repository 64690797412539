import React, { useEffect, useState } from "react";
import config from "../../config/config";
import { useDispatch, useSelector } from "react-redux";
import { incrementQuantity, decrementQuantity } from "../../redux/cartSlice";
import { FiPlusCircle, FiMinusCircle } from "react-icons/fi";

const checkIfImageExists = (url, callback) => {
  const img = new Image();
  img.src = url;

  if (img.complete) {
    callback(true);
  } else {
    img.onload = () => {
      callback(true);
    };

    img.onerror = () => {
      callback(false);
    };
  }
};

function MenuItems({ menuDishes, placeOrder, onDetailOpen, supaProduct }) {
  const dispatch = useDispatch();
  const cartData = useSelector((state) => state.cart.cart);

  const defaultImage = `/assets/${process.env.REACT_APP_MODE}/logo/logo.png`;

  const [imageURL, setImageURL] = useState(defaultImage);
  const [loading, setLoading] = useState(false);

  const cartItemData = cartData.filter(
    (item) => menuDishes.item_no === item.id
  );
  const increaseQty = (uuid) => {
    dispatch(incrementQuantity({ uuid }));
  };
  const decreaseQty = (uuid) => {
    dispatch(decrementQuantity({ uuid }));
  };

  const originalImageUrl = supaProduct?.image_url;

  useEffect(() => {
    setLoading(true);

    checkIfImageExists(originalImageUrl, (exists) => {
      if (exists) {
        setImageURL(originalImageUrl);
      } else {
        setImageURL(defaultImage);
      }
    });

    setLoading(false);
  }, [defaultImage, menuDishes.item_no, supaProduct?.image_url]);

  const handlePlaceOrder = (item) => {
    if (item.is_soldout === "Y") {
      return;
    }
    placeOrder(item);
  };

  return (
    <>
      <div style={{ position: "relative" }} className="menuitem p-2 d-flex">
        <div id={menuDishes.category_code}>
          {/* {menuDishes.category_code} */}
        </div>

        {!loading && (
          <img
            src={imageURL}
            style={{
              ...(menuDishes.is_soldout === "Y" && { filter: "grayscale(1)" }),
            }}
            className="carousel-cls"
            alt=""
            onClick={() => onDetailOpen(menuDishes)}
          />
        )}
        {supaProduct?.isTopPick ? (
          <div className="d-flex top-pick-tag ms-2">
            <span className="best-seller-text text-white">Top Pick!</span>
          </div>
        ) : (
          ""
        )}
        <div
          className="d-flex flex-column text-start"
          style={{ width: "100%" }}>
          <span className="menu-title mt-3 text-break text-uppercase">
            {menuDishes.item_desc}
            {menuDishes.bal_qty > 0 ? (
              <span className="bal-qty-text mt-2">
                ({menuDishes.bal_qty - (cartItemData[0]?.qty || 0)} left)
              </span>
            ) : (
              ""
            )}
          </span>
          {supaProduct?.isTopPick ? (
            <div
              className="d-flex top-pick-tag mt-1 mb-1"
              style={{ position: "relative", top: "0", left: "0" }}>
              <span className="best-seller-text text-white">Top Pick!</span>
            </div>
          ) : (
            ""
          )}

          {/* <span className="menu-price-text mt-2">
            $
            {Number(
              menuDishes?.selling_uom_dtls[0]?.price_dtls[0]?.dine_in_price
            ).toFixed(2)}
          </span> */}
          <div className="d-flex flex-row align-items-end mb-2 mt-auto">
            <p
              style={{
                color: "orange",
                fontSize: "17px",
                lineHeight: "17px",
                margin: "0",
              }}
              className="menu-price-text mt-2 me-2">
              $
              {Number(
                menuDishes?.selling_uom_dtls?.[0]?.price_dtls?.[0]
                  ?.dine_in_price
              ).toFixed(2)}
            </p>
            {supaProduct?.dummyPrice && (
              <p
                style={{
                  textDecoration: "line-through",
                  fontSize: "12px",
                  lineHeight: "17px",
                  marginBottom: "0px",
                }}
                className="dummy-price-text mt-2">
                ${Number(supaProduct?.dummyPrice).toFixed(2)}
              </p>
            )}
          </div>
        </div>
        <div
          className="mt-1"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}>
          {cartItemData.length !== 0 &&
          cartItemData[0]?.item?.itemmaster_menutype_grpdtls === "" ? (
            <div style={{ position: "absolute", top: "50%" }}>
              <div style={{ position: "relative" }}>
                <FiPlusCircle
                  size={30}
                  className="icon-counter icon-plus-menu"
                  onClick={() => increaseQty(cartItemData[0].uuid)}
                />
                <span
                  style={{ marginRight: "12px" }}
                  className="counter-number">
                  {cartItemData[0].qty}
                </span>
                <FiMinusCircle
                  onClick={() => decreaseQty(cartItemData[0].uuid)}
                  size={30}
                  className="icon-counter icon-minus-menu"
                />
              </div>
            </div>
          ) : (
            <FiPlusCircle
              size={30}
              className="icon-counter"
              onClick={() => handlePlaceOrder(menuDishes)}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default MenuItems;
