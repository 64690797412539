import React, { useEffect, useState } from "react";
import checkIfImageExists from "../../utils/checkIfImageExists";

const CartItemImage = ({ productsSupabase, data }) => {
  const defaultImage = `/assets/${process.env.REACT_APP_MODE}/logo/logo.png`;

  const [imageUrl, setImageUrl] = useState(defaultImage);

  const findProduct = productsSupabase?.[data?.item_no];

  const originalImageUrl = findProduct?.image_url;

  useEffect(() => {
    checkIfImageExists(originalImageUrl, (exists) => {
      if (exists) {
        setImageUrl(originalImageUrl);
      } else {
        setImageUrl(defaultImage);
      }
    });
  }, [originalImageUrl]);

  return imageUrl && <img src={imageUrl} alt="" className="cart-img" />;
};

export default CartItemImage;
