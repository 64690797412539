import React, { useEffect, useState } from "react";
// import DateTimePicker from "react-datetime-picker";
import { Modal, Button } from "react-bootstrap";
// import "react-datetime-picker/dist/DateTimePicker.css";
import { STORAGEKEY } from "../../constants";
import { AiOutlineClose } from "react-icons/ai";
import {
  LocalizationProvider,
  DateTimePicker,
  StaticDateTimePicker,
  MultiSectionDigitalClock,
  DigitalClock,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import useBrandConfig from "../../hooks/useBrandConfig";

const DateTimePickerModal = ({
  isOpen,
  onRequestClose,
  onDateTimeSelected,
}) => {
  const [selectedDateTime, setSelectedDateTime] = useState(new Date());

  const [showClock, setShowClock] = useState(false);

  const { config } = useBrandConfig();

  const [openStoreStart, openStoreEnd] = config?.openStore?.split(":") || [];
  const [closeStoreStart, closeStoreEnd] = config?.closeStore?.split(":") || [];

  // hide clock when modal is closed
  useEffect(() => {
    if (!isOpen) {
      setShowClock(false);
    }
  }, [isOpen]);

  const handleShowClock = () => {
    setShowClock(true);
  };

  const handleDateTimeChange = (date) => {
    setSelectedDateTime(date);
    // format date as Mon, 1 Jan 2021, 12:00 PM, do not display timezone and seconds
    // use moment.js to format date
    const formattedDate = moment(date).format("D MMM, h:mm A");

    localStorage.setItem(STORAGEKEY.ORDER_TIME, formattedDate); // date is a Date object
  };

  const handleNowOption = () => {
    const now = new Date();
    const futureDateTime = new Date(now.getTime() + 15 * 60000); // Adding 15 minutes (15 * 60 * 1000 milliseconds)
    setSelectedDateTime(futureDateTime);
    localStorage.setItem(STORAGEKEY.ORDER_TIME, "now"); // 'now' is a string
    onRequestClose();
  };

  const handleConfirm = () => {
    onDateTimeSelected(selectedDateTime);
    onRequestClose();
  };

  const minDate = () => {
    const today = new Date();

    today.setHours(openStoreStart);
    today.setMinutes(openStoreEnd);

    return moment(today);
  };

  const maxDate = () => {
    const today = new Date();

    today.setHours(closeStoreStart);
    today.setMinutes(closeStoreEnd);

    return moment(today).subtract(30, "minutes");
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Modal show={isOpen} onHide={onRequestClose} centered>
        <Modal.Header className="modal-header" closeButton closeVariant="white">
          <Modal.Title className="text-uppercase">
            Pick up or Pre-Order?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center flex-column justify-content-center text-center">
            <div className="d-flex flex-row justify-content-between">
              <Button
                variant="secondary"
                className="modal-button text-uppercase fw-bold mx-2"
                onClick={handleNowOption}>
                Collect Now
              </Button>

              <Button
                variant="primary"
                className="modal-button text-uppercase fw-bold mx-2"
                onClick={handleShowClock}>
                Collect Later
              </Button>
            </div>

            {showClock && (
              <>
                <div className="timepicker-body mt-3">
                  <DigitalClock
                    timeStep={10}
                    minTime={minDate()}
                    maxTime={maxDate()}
                    autoFocus={true}
                    onChange={handleDateTimeChange}
                    skipDisabled
                    // maxTime="21:00"
                    // display time until 9pm
                    // maxTime={"21:00"}
                  />
                </div>
                <div className="mt-3">
                  <Button
                    variant="primary"
                    className="modal-button text-uppercase fw-bold"
                    onClick={handleConfirm}>
                    Confirm
                  </Button>
                </div>
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </LocalizationProvider>
  );
};

export default DateTimePickerModal;
