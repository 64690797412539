export const STORAGEKEY = {
  SESSION_ID: "SESSION_ID",
  STORE_NAME: "storeName",
  TABLE_NO: "tableNo",
  QR_CODE: "QRCode",
  DINING_MODE: "selectiDineOption",
  TAKEOUT_OPTION: "takeoutOption",
  ORDER_TIME: "orderTime",
  SESSION_TIMEOUT: "sessionTimeout",
  WELCOME_BANNER: "welcomeBanner",
};
