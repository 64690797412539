import React, {
  useEffect,
  useRef,
  useState,
  useLayoutEffect,
  useMemo,
  useCallback,
} from "react";
import Header from "../../component/header";
import CarouselBanner from "./CarouselBanner";
import Scrollspy from "react-scrollspy-ez";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MdOutlineShoppingCart } from "react-icons/md";
import { SlBag } from "react-icons/sl";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { Button, Spinner } from "react-bootstrap";
import {
  getServiceCharges,
  storeMenuButton,
  storeMenuItems,
  storeStockList,
} from "../../services/tableQRServices";
import _, { isEmpty, sortBy } from "lodash";
import MenuItems from "./MenuItems";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCart,
  setIsGST,
  setIsGSTInclusive,
  setIsServiceCharge,
  setTaxList,
  setTaxValue,
  setTotal,
} from "../../redux/cartSlice";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { CustomizeModal } from "./CustomizeModal";
import { getCategories } from "../../services/supabase/category";
import { getProducts } from "../../services/supabase/product";
// import { RiContactsBookLine } from "react-icons/ri";

import DateTimePickerModal from "./DateTimePickerModal";
import { STORAGEKEY } from "../../constants";
import { FiEdit } from "react-icons/fi";
import UpsellingModal from "../../component/Upselling/UpsellingModal";
import getUpsellingItems from "../../utils/getUpsellingitems";
import MenuDetail from "./MenuDetail";
import useBrandConfig from "../../hooks/useBrandConfig";
import useSessionTimeout from "../../hooks/useSessionTimeout";
import WelcomeBanner from "../../component/WelcomeBanner/WelcomeBanner";
import useCategory from "../../hooks/useCategory";
import usePromotion from "../../hooks/usePromotion";
import cx from "classnames";
import {
  setPosFullItemList,
  setPosMenuButton,
  setPosStoreStockList,
} from "../../redux/posSlice";
import { setOpenConfetti, setServiceChargeList } from "../../redux/appSlice";

function Home() {
  const [activeCategory, setActiveCategory] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const navigationData = location.state;
  const {
    handleConvertPromotionItem,
    handleConvertPromotionTotal,
    handleConvertPromotionCart,
  } = usePromotion();

  const [isBottomOpen, setIsBottomOpen] = useState(false);
  const [enableOverlay, setEnableOverlay] = useState(true);
  const [showSearchBar, setShowSearchBar] = useState(false);
  // const [time, setTime] = useState("12:34pm");
  const [menuCategories, setMenuCategories] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [searchedMenuItems, setSearchedMenuItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showUpsellingModal, setShowUpsellingModal] = useState(false);
  const [upsellingItems, setUpsellingItems] = useState([]);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [selectedItem, setSelectedMenuItem] = useState({});

  const dispatch = useDispatch();

  // setIsLoading(false);

  // get the number of items in the cart
  const cartItems = useSelector((state) => state.cart.cart);
  // get items from the cart and then read the qty of each item
  // sum the items to get cart items count each time an item is added to the cart
  const cartItemsCount = cartItems.reduce((acc, item) => acc + item.qty, 0);
  const { posStoreStockList, posMenuButton, posFullItemList } = useSelector(
    (state) => state.pos
  );

  const [cartCount, setCartCount] = useState(cartItems.length);
  const [showCustomizeModal, setShowCustomizeModal] = useState(false);
  const [showModalData, setShowModalData] = useState([]);
  const [supaProducts, setSupaProducts] = useState({});
  const [allCategories, setAllCategories] = useState([]);
  const [allShowCategories, setAllShowCategories] = useState([]);
  const [originalCategories, setOriginalCategories] = useState([]);
  const [originalPOSCategories, setOriginalPOSCategories] = useState([]);
  const [mainCategories, setMainCategories] = useState([]);

  const { config } = useBrandConfig();
  const sessionTimeout = useSessionTimeout();
  const { checkingEmptyCategory } = useCategory();

  const handleSelectDineOption = () => {
    if (config?.serviceMode === "DineInOnly") {
      localStorage.setItem("selectiDineOption", "dinein");
    } else if (config?.serviceMode === "TakeAwayOnly") {
      if (config?.enablePreOrder) {
        openModal();
      }
      localStorage.setItem("selectiDineOption", "takeaway");
    }
  };

  useEffect(() => {
    let getStorageOption = localStorage.getItem("selectiDineOption");

    if (getStorageOption === "" || getStorageOption === null) {
      if (config?.isDineOption && config?.serviceMode === "DineInAndTakeAway") {
        setIsBottomOpen(true);
        handleSelectDineOption();
      } else {
        setIsBottomOpen(false);
        handleSelectDineOption();
      }
    }

    return () => {
      // Clear the listener on component destroyed
      if (menuRef && menuRef.current && menuRef.current.removeEventListener) {
        menuRef.current.removeEventListener("scroll", calculateScrollFn);
      }
    };
  }, [config]);

  const subTotal = useSelector((state) => state.cart.total);
  const dispatcher = useDispatch();

  const itemsListAndCategory = async () => {
    const resultParse = JSON.parse(posStoreStockList);

    const newResultParse = resultParse?.filter(
      (record) => record?.is_emenu_disable !== "Y"
    );
    const categoryNames = allCategories.map((item) => item.name);

    let resultItemsParsed = newResultParse.filter(
      (category) => category.avl_type === "I"
    );
    await menuButtonFunction(
      categoryNames,
      resultItemsParsed,
      newResultParse,
      resultParse
    );
  };

  const menuButtonFunction = async (
    categoryCodes,
    resultItemsParsed,
    resultParse,
    originalResultParse
  ) => {
    let newCategoryCodes = [];

    categoryCodes?.forEach((record) => {
      const findCategory = originalResultParse?.find(
        (cat) => cat?.item_category === record
      );

      if (
        findCategory &&
        findCategory?.is_emenu_disable !== "Y" &&
        findCategory?.is_soldout !== "Y"
      ) {
        newCategoryCodes.push(record);
      } else if (!findCategory) {
        newCategoryCodes.push(record);
      }
    });

    const categoryMenuButton = posMenuButton;

    let categoryMainMenu = categoryMenuButton.filter(
      // (menuItem) => menuItem.items && menuItem.items.length === 0
      // (menuItem) => menuItem.category && menuItem.items.length > 0
      // (menuItem) => menuItem.root_category_code === "MAIN"
      // filter menuItem.items length > 0
      (menuItem) => menuItem.items.length > 0
    );

    let categoryMainMenuButton = [];
    for (const catItems of categoryMainMenu) {
      // categoryMainMenuButton.push(...catItems.category);
      if (catItems.category.length == 0) {
        // categoryMainMenuButton.push(...catItems.category);
        categoryMainMenuButton.push(catItems);
      }
    }

    // categoryMainMenuButton = categoryMainMenu; // temp fix

    if (categoryMainMenuButton && categoryMainMenuButton.length > 0) {
      let categoriesIndex = categoryMainMenuButton;
      // categoryMainMenuButton = categoryMainMenuButton[0];
      // let categoriesIndex = categoryMainMenuButton.category;
      // ? Sort on sequences and filter on start and end time
      categoriesIndex = categoriesIndex
        .sort((a, b) => a.course_seq - b.course_seq)
        .filter(
          (category) =>
            category.start_time <= moment().format("HH:mm:ss") &&
            category.end_time > moment().format("HH:mm:ss")
        );

      // categoryCodes = [];
      // for (const category of categoriesIndex) {
      //   categoryCodes.push(category.category_code);
      // }
      newCategoryCodes = categoryMenuButton
        .filter((category) => category.items !== "")
        .map((category) => {
          return category.root_category_code;
        });

      const categoregoryFromApi = categoryMenuButton.map((category) => {
        return category.root_category_code;
      });

      // NOTE sort by supabaseCategories
      newCategoryCodes = _.intersection(newCategoryCodes, categoregoryFromApi);
      // for (const category of categoriesIndex) {
      //   categoryCodes.push(category.category_code);
      // }
      let menuItemsArray = [];
      for (const category of newCategoryCodes) {
        const catItem = categoryMenuButton.filter(
          (catItems) => catItems.root_category_code === category
        );
        if (
          catItem &&
          catItem.length > 0 &&
          catItem[0].items &&
          catItem[0].items.length > 0
        ) {
          menuItemsArray.push(...catItem[0].items);
        }
      }
      // ? Intersect items from stock and button api
      let intersectCategory = _.intersectionBy(
        resultItemsParsed,
        menuItemsArray,
        (obj) => obj.item_category || obj.item_no
      );
      // ? Intersect items from with intersected items and button api items
      intersectCategory = Object.values(
        _.merge(
          _.keyBy(intersectCategory, "item_category"),
          _.keyBy(menuItemsArray, "item_no")
        )
      ).filter(
        (item) =>
          item.is_emenu_disable === "N" &&
          item.start_time <= moment().format("HH:mm:ss") &&
          item.end_time > moment().format("HH:mm:ss")
      );

      const newSupaCategories = originalCategories?.filter(
        (record) => record?.is_hidden !== true && record?.is_hidden !== "Y"
      );

      // NOTE merge vendor category and supabase category
      const mergedCategoryPromise = _.map(
        mainCategories,
        async (categoryName) => {
          const matchedItem = _.find(newSupaCategories, {
            name: categoryName?.name,
          });

          const findSubCategory = originalPOSCategories?.find(
            (record) => record?.root_category_code === categoryName?.name
          );
          let formatSubCategory = [];

          if (findSubCategory && findSubCategory?.category) {
            findSubCategory?.category?.forEach((record) => {
              if (
                allCategories?.find(
                  (cat) => cat?.name === record?.category_code
                )
              ) {
                const findCategory = allCategories?.find(
                  (cat) => cat?.name === record?.category_code
                );
                formatSubCategory.push(findCategory);
              }
            });

            const formatCategory = await checkingEmptyCategory(
              allCategories,
              formatSubCategory?.map((record) => record?.name)
            );

            formatSubCategory = formatCategory;
          } else if (
            !findSubCategory?.category &&
            mainCategories?.find(
              (record) => record?.name === findSubCategory?.root_category_code
            )
          ) {
            formatSubCategory = [];
          }

          if (matchedItem) {
            return {
              name: categoryName?.name,
              short: matchedItem?.short_name,
              imageUrl: matchedItem?.header_image_url,
              subCategory: formatSubCategory,
            };
          }
        }
      );

      const mergedCategory = await Promise.all(mergedCategoryPromise);

      setMenuCategories(
        mergedCategory?.filter((item) =>
          item?.subCategory?.includes(undefined)
            ? item?.subCategory?.filter((record) => record)?.length > 0
            : true
        )
      );
      menuItemsFunction(
        intersectCategory,
        newCategoryCodes,
        originalResultParse
      );
    }
  };

  const menuItemsFunction = (
    intersectCategory,
    categoryCodes,
    originalResultParse
  ) => {
    const result = posFullItemList;

    const filterItemMaster = result.filter(
      (item) =>
        item.itemmaster_menutypedtls && item.itemmaster_menutypedtls.length > 0
    );

    const stockedCheckedItem = [];
    if (filterItemMaster && filterItemMaster.length > 0) {
      for (const itemMaster of filterItemMaster) {
        let itemmaster_menutypedtls = [];
        for (const typeDtls of itemMaster.itemmaster_menutypedtls) {
          const modifierQty = originalResultParse.filter(
            (itemCategory) => itemCategory.item_category === typeDtls.citem_no
          );
          const findItem = originalResultParse?.find(
            (record) => record?.item_category === typeDtls.citem_no
          );
          const checkingDisabledMenu =
            findItem?.is_emenu_disable === "N" && findItem?.is_soldout === "N";

          if (checkingDisabledMenu) {
            if (modifierQty && modifierQty.length > 0) {
              itemmaster_menutypedtls.push({
                ...typeDtls,
                is_soldout: modifierQty[0].is_soldout,
                is_emenu_disable: modifierQty[0].is_emenu_disable,
                is_avl_limit_check: modifierQty[0].is_avl_limit_check,
                bal_qty: modifierQty[0].bal_qty,
              });
            } else {
              itemmaster_menutypedtls.push({ ...typeDtls });
            }
          } else if (!findItem) {
            itemmaster_menutypedtls.push({ ...typeDtls });
          }
        }

        stockedCheckedItem.push({ ...itemMaster, itemmaster_menutypedtls });
      }
    }
    const filterNoItemMaster = result.filter(
      (item) =>
        !item.itemmaster_menutypedtls ||
        item.itemmaster_menutypedtls.length <= 0
    );

    let intersectItems = _.intersectionBy(
      intersectCategory,
      [...filterNoItemMaster, ...stockedCheckedItem],
      (obj) => obj.item_category || obj.item_no
    ).filter((item) => item.is_active);

    intersectItems = Object.values(
      _.merge(
        _.keyBy(intersectCategory, "item_category"),
        _.keyBy([...filterNoItemMaster, ...stockedCheckedItem], "item_no")
      )
    )
      .filter(
        (item) => item.is_active && categoryCodes.includes(item.category_code)
      )
      .filter((item) => item.is_emenu_disable === "N");

    const sortAlphabetically = intersectItems.sort((a, b) =>
      a.item_desc.localeCompare(b.item_desc)
    );

    const sortByCategory = sortAlphabetically
      .sort(
        (a, b) =>
          categoryCodes.indexOf(a.category_code) -
          categoryCodes.indexOf(b.category_code)
      )
      .filter(
        (item) =>
          item.start_time <= moment().format("HH:mm:ss") &&
          item.end_time > moment().format("HH:mm:ss")
      );

    console.log("sortByCategory", sortByCategory);
    setMenuItems(sortByCategory);
    setIsLoading(false);
  };

  const fetchSupabaseCategoriesOriginal = async () => {
    setIsLoading(true);

    const resp = await getCategories(process.env.REACT_APP_MODE);
    const respMainCategory = await storeMenuButton();

    setOriginalCategories(resp.data);
    setOriginalPOSCategories(respMainCategory?.data?.data?.[0]?.output);
  };

  const fetchSupabaseCategories = async () => {
    const filterCategory = originalCategories?.filter(
      (record) => record?.is_hidden !== true && record?.is_hidden !== "Y"
    );

    let formatAllCategories = [];

    (
      originalPOSCategories?.find(
        (record) => record?.root_category_code === "MAIN"
      )?.category || []
    )
      ?.filter(
        (category) =>
          category.start_time <= moment().format("HH:mm:ss") &&
          category.end_time > moment().format("HH:mm:ss")
      )
      ?.forEach((record) => {
        const findCategory = filterCategory?.find(
          (cat) => cat?.name === record?.category_code
        );

        if (findCategory) {
          const findCategory = filterCategory?.find(
            (cat) => cat?.name === record?.category_code
          );
          formatAllCategories.push(findCategory);
        }
      });

    formatAllCategories = formatAllCategories.sort(
      (a, b) => a?.sequence - b?.sequence
    );

    const formatSubCategory = [];

    formatAllCategories
      ?.map((record) => record?.name)
      .forEach((cat) => {
        const findSubCategory = originalPOSCategories?.find(
          (record) => record?.root_category_code === cat
        );

        if (
          findSubCategory &&
          !findSubCategory?.category &&
          findSubCategory?.items
        ) {
          const findCategory = filterCategory?.find(
            (cat) => cat?.name === findSubCategory?.root_category_code
          );

          if (findCategory) {
            formatSubCategory.push(findCategory);
          }
        }
      });

    const formatCategory = await checkingEmptyCategory(
      filterCategory,
      formatSubCategory?.map((record) => record?.name)
    );

    const filterAllCategories = formatAllCategories?.filter(
      (record) =>
        !formatSubCategory
          ?.map((record) => record?.name)
          ?.includes(record?.name)
    );
    const filterFormatCategories = [];

    formatCategory
      ?.filter((record) => record)
      ?.forEach((record) => {
        const findCategory = filterCategory?.find(
          (cat) => cat?.name === record
        );

        if (findCategory) {
          filterFormatCategories.push(findCategory);
        }
      });

    setMainCategories(
      formatAllCategories?.sort((a, b) => a?.sequence - b?.sequence)
    );
    setAllShowCategories(
      [...filterAllCategories, ...filterFormatCategories]
        ?.sort((a, b) => a?.sequence - b?.sequence)
        ?.map((record) => record?.name)
    );
    setAllCategories(filterCategory?.sort((a, b) => a?.sequence - b?.sequence));
  };

  const fetchSupabaseProducts = async () => {
    const resp = await getProducts(process.env.REACT_APP_MODE);
    if (!resp.error) {
      const hashedProducts = resp.data.reduce((result, item) => {
        result[item.pos_item_no] = {
          name: item?.name,
          dummyPrice: item?.dummy_price,
          description: item?.description,
          image_url: item?.image_url,
        };
        return result;
      }, {});
      setSupaProducts(hashedProducts);
      return hashedProducts;
    }
    return null;
  };

  useEffect(() => {
    fetchSupabaseCategoriesOriginal();
    fetchSupabaseProducts();
  }, []);

  useEffect(() => {
    if (
      originalCategories?.length > 0 &&
      originalPOSCategories?.length > 0 &&
      posStoreStockList &&
      JSON.parse(posStoreStockList)?.length > 0 &&
      posMenuButton?.length > 0 &&
      posFullItemList?.length > 0
    ) {
      fetchSupabaseCategories();
    }
  }, [
    originalCategories?.length,
    originalPOSCategories?.length,
    posFullItemList,
    posStoreStockList,
    posMenuButton,
  ]);

  useEffect(() => {
    if (
      mainCategories?.length > 0 &&
      originalCategories?.length > 0 &&
      originalPOSCategories?.length > 0 &&
      allCategories?.length > 0 &&
      posStoreStockList &&
      JSON.parse(posStoreStockList)?.length > 0 &&
      posMenuButton?.length > 0 &&
      posFullItemList?.length > 0
    ) {
      itemsListAndCategory();
    }
  }, [
    allCategories?.length,
    mainCategories?.length,
    originalCategories?.length,
    originalPOSCategories?.length,
    posStoreStockList,
    posMenuButton,
    posFullItemList,
  ]);

  let menuItem, menuDish;
  menuItem = menuCategories;
  menuDish = searchedMenuItems.length !== 0 ? searchedMenuItems : menuItems;
  const placeOrder = async (dishDetail) => {
    if (dishDetail.itemmaster_menutype_grpdtls === "") {
      const itemInCart = cartItems.find(
        (item) =>
          item.id === dishDetail.item_no &&
          item.addOnValues.length === 0 &&
          _.isEqual(_.map(item.addOnValues, "item_no"), _.map([], "item_no"))
      );

      dispatcher(
        addToCart(
          handleConvertPromotionItem({
            uuid: itemInCart?.uuid,
            id: dishDetail.item_no,
            addOnValues: [],
            item: dishDetail,
            qty: 1,
            price: dishDetail.selling_uom_dtls[0]?.price_dtls[0]?.dine_in_price,
          })
        )
      );
      toast.success(`${dishDetail.item_name}  ADDED TO CART`, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
      if (config?.enableConfetti) {
        dispatcher(setOpenConfetti(true));
      }
      const { hasUpsellingItems, items: upsellItems } = await getUpsellingItems(
        dishDetail.category_code,
        dishDetail.item_category
      );
      if (hasUpsellingItems) {
        setUpsellingItems(upsellItems);
        setShowUpsellingModal(true);
      }
      // navigate("/cart");
    } else {
      const customDetails = {
        item: dishDetail,
      };

      if (config?.usingCustomizePage) {
        navigate("/customize-dish", {
          state: customDetails,
        });
      } else {
        setShowModalData(customDetails);
        setShowCustomizeModal(true);
      }
    }
  };
  const toggleBottom = () => {
    if (
      localStorage.getItem("selectiDineOption") === "dinein" ||
      localStorage.getItem("selectiDineOption") === "takeaway"
    ) {
      setIsBottomOpen((prev) => !prev);
    }
  };

  const openDiningOption = () => {
    setIsBottomOpen(true);
  };

  const selectDining = (selectionOption) => {
    localStorage.setItem("selectiDineOption", selectionOption);
    // set current cart to dine in
    setIsBottomOpen(false);
  };
  const searchFunction = (values) => {
    if (values && values.length > 0) {
      setSearchedMenuItems(
        menuItems.filter(
          (item) =>
            item.item_name.includes(values) ||
            item.item_name.toLowerCase().includes(values.toLowerCase()) ||
            item.item_name.toUpperCase().includes(values.toUpperCase())
        )
      );
    } else {
      // itemsListAndCategory();
      setSearchedMenuItems(menuItems);
    }
  };
  const handleSearchClick = () => {
    setShowSearchBar(!showSearchBar);
  };

  const [initialized, setInitialized] = useState(false);
  const menuRef = useRef();

  let pendingAction;
  const calculateScrollFn = () => {
    if (pendingAction) {
      clearTimeout(pendingAction);
      pendingAction = false;
    }
    pendingAction = setTimeout(() => {
      const activeMenuLi = document.querySelector(".spyItemClass.active");
      if (!activeMenuLi) {
        return;
      }
      const menuContainer = activeMenuLi.parentNode;

      const elementRect = activeMenuLi.getBoundingClientRect();
      const containerRect = menuContainer.getBoundingClientRect();

      let scrollGap;
      if (elementRect.top < containerRect.top) {
        scrollGap = elementRect.top - containerRect.top;
      }
      if (elementRect.bottom > containerRect.bottom) {
        scrollGap = elementRect.bottom - containerRect.bottom;
      }
      if (scrollGap) {
        menuContainer.scrollBy(0, scrollGap);
      }
    }, 50);
  };

  function initScrollListener() {
    if (initialized) {
      return;
    }
    if (!menuRef || !menuRef.current) {
      return;
    }
    menuRef.current.removeEventListener("scroll", calculateScrollFn);
    menuRef.current.addEventListener("scroll", calculateScrollFn);
    setInitialized(true);
  }

  // date and time picker

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDateTime, setSelectedDateTime] = useState(null);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleDateTimeSelected = (dateTime) => {
    setSelectedDateTime(dateTime);
  };

  useEffect(() => {
    if (menuItem.length === 0) return;
    const handleScroll = () => {
      const scrollContainer = document.querySelector(".home-div");
      const scrollTop = scrollContainer.scrollTop;

      // Calculate the active category based on the scroll position
      const categories = scrollContainer.getElementsByClassName("category");

      let currentCategory = null;

      for (let i = 0; i < categories.length; i++) {
        const category = categories[i];

        if (!category) {
          return;
        }

        const offset = 0;
        const rect = category.getBoundingClientRect();

        if (
          rect.top >= 0 - offset &&
          rect.bottom <= window.innerHeight + offset + scrollTop
        ) {
          currentCategory = category.getAttribute("data-category");

          break;
        }
      }

      if (scrollTop > 0 && currentCategory === null) return;

      setActiveCategory(currentCategory);
    };
    let scrollContainer = document.querySelector(".home-div");

    if (scrollContainer)
      scrollContainer.addEventListener("scroll", handleScroll);

    return () => {
      if (scrollContainer)
        scrollContainer.removeEventListener("scroll", handleScroll);
    };
  }, [activeCategory, menuItem]);

  const categoryAndMenus = useMemo(() => {
    return menuItem.map((item) => {
      return {
        menuCategory: item,
        subCategory: item?.subCategory,
        subCategoryList: item?.subCategory?.map((record) => ({
          name: record,
          menus: menuDish.filter(
            (menuDishes) => menuDishes?.category_code === record
          ),
        })),
        menus: menuDish.filter(
          (menuDishes) => menuDishes?.category_code === item?.name
        ),
      };
    });
  }, [menuDish, menuItem]);

  const handleCustomModalSuccessSubmit = async (submittedItem) => {
    const { hasUpsellingItems, items: upsellItems } = await getUpsellingItems(
      submittedItem.category_code,
      submittedItem.item_category
    );
    if (hasUpsellingItems) {
      setUpsellingItems(upsellItems);
      setShowUpsellingModal(true);
    }
  };
  const getImageUrlByCategoryName = (name, array) => {
    const item = array.find((obj) => obj?.name === name || obj?.short === name);
    return item ? item.imageUrl : null;
  };
  const toggleDetailModal = (data = {}) => {
    setSelectedMenuItem(data);
    if (showDetailModal) {
      setShowDetailModal(false);
    } else {
      setShowDetailModal(true);
    }
  };

  const formatCategory = categoryAndMenus.filter((record) => {
    const convertSubCategory = record?.subCategoryList?.map(
      (record) => record?.name
    );

    return (
      (convertSubCategory?.length > 0 ? convertSubCategory : record?.menus)
        .length !== 0
    );
  });

  useEffect(() => {
    if (menuItem?.length > 0) {
      setActiveCategory(menuItem?.[0]?.name);
    }
  }, [menuItem]);

  const handleScrollCategory = (category) => {
    const elementCategory = document.getElementById(category);

    elementCategory.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  const handleGetStockList = async () => {
    setIsLoading(true);

    const res = await storeStockList();

    const result = res?.data?.data[0]?.output;

    dispatch(setPosStoreStockList(result));
  };

  const handleGetMenuButton = async () => {
    const res = await storeMenuButton();

    const categoryMenuButton = res?.data?.data[0]?.output;

    dispatch(setPosMenuButton(categoryMenuButton));
  };

  const handleGetMenuItems = async () => {
    const res = await storeMenuItems();

    const result = res?.data?.data[0]?.output;

    dispatch(setPosFullItemList(result));
  };

  const handleGetServiceChanges = async () => {
    const res = await getServiceCharges();

    dispatch(
      setServiceChargeList(
        res?.data?.data?.[0]?.output
          ?.filter((record) => {
            const storeNameList = record?.store?.map(
              (store) => store?.store_name
            );

            return storeNameList?.includes(
              localStorage.getItem(STORAGEKEY.STORE_NAME)
            );
          })
          .map((record) => ({
            ...record,
            taxAmt: record?.service_value / 100,
          }))
      )
    );
  };

  useLayoutEffect(() => {
    if (
      !posStoreStockList &&
      !posMenuButton?.length &&
      !posFullItemList?.length
    ) {
      handleGetStockList();
      handleGetMenuButton();
      handleGetMenuItems();
      handleGetServiceChanges();
    }
  }, [posFullItemList, posMenuButton, posStoreStockList]);

  const formatPromotionCartData = handleConvertPromotionCart(cartItems);

  useEffect(() => {
    if (formatPromotionCartData?.length > 0) {
      dispatcher(setTotal(formatPromotionCartData));
    }
  }, [formatPromotionCartData]);

  const clearState = () => {
    navigate(location.pathname, { replace: true });
  };

  const checkUpselling = async (categoryCode, itemCategory) => {
    const { hasUpsellingItems, items: upsellItems } = await getUpsellingItems(
      categoryCode,
      itemCategory
    );
    if (hasUpsellingItems) {
      setUpsellingItems(upsellItems);
      setShowUpsellingModal(true);
    }
  };

  const handleDispatchCartTax = useCallback(
    (data) => {
      dispatcher(setIsGSTInclusive(data?.is_absorbtax ? true : false));
      dispatcher(setIsServiceCharge(data?.is_apply_svc ? true : false));
      dispatcher(
        setIsGST(data?.tax_name === "ZERO" || !data?.tax_name ? false : true)
      );
      dispatcher(setTaxValue(Number(data?.tax_value)));
    },
    [dispatcher]
  );

  useEffect(() => {
    if (!isEmpty(navigationData)) {
      const {
        item: { category_code, item_category },
      } = navigationData;
      checkUpselling(category_code, item_category);
    }
  }, [navigationData]);

  const handleTaxLogicFromPOS = () => {
    dispatcher(setTaxList(menuDish));
  };

  useEffect(() => {
    if (menuDish?.length > 0) {
      handleTaxLogicFromPOS();
    }
  }, [menuDish]);

  return (
    <>
      <div className="App">
        <div className="qr-before-body w-100">
          <Header
            title="home"
            showSearchBar={showSearchBar}
            handleSearchClick={handleSearchClick}
            searchFunction={searchFunction}
            openDiningOption={openDiningOption}
          />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <DateTimePickerModal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            onDateTimeSelected={handleDateTimeSelected}
          />
          <div className="pickup-info position-sticky">
            {/* display whether the order is for pickup or pre-order. have an icon to click to open up the datetimepickermodal for changing to pre-order */}
            <div className="d-flex align-items-center">
              {/* show pick now if localStorage.getItem(STORAGEKEY.ORDER_TIME === 'now') is true, else show at and value of localStorage.getItem(STORAGEKEY.ORDER_TIME) */}
              <span className="mt-1 text-uppercase">
                {!localStorage.getItem(STORAGEKEY.ORDER_TIME) ||
                localStorage.getItem(STORAGEKEY.ORDER_TIME) === "now"
                  ? "Pick up now"
                  : `Pick up on ${localStorage.getItem(STORAGEKEY.ORDER_TIME)}`}
              </span>
              {config?.enablePreOrder && (
                <span className="ms-5 mt-1">
                  <FiEdit
                    size={24}
                    onClick={openModal}
                    style={{ cursor: "pointer" }}
                  />
                </span>
              )}
            </div>
          </div>
          <div
            className="main-div home-div position-absolute w-100"
            ref={menuRef}>
            {config?.home_banner_enabled && <CarouselBanner />}
            <div className="top-border">
              {!initialized && initScrollListener()}
              {isLoading ? (
                <Spinner animation="border material-icons position-absolute top-50" />
              ) : (
                <>
                  {menuItem && menuItem.length > 0 ? (
                    <>
                      {/* <Scrollspy
                        ids={menuItem.map((item) => item?.name)}
                        itemContainerClassName="scrollSpyContainer top-0 end-0 p-0 menu-cls"
                        activeItemClassName="active"
                        itemClassName="spyItemClass text-start"
                        offset={100}
                        includeParentClasses={true}
                      /> */}
                      <ul className="scrollSpyContainer top-0 end-0 p-0 menu-cls">
                        {menuItem
                          ?.filter((record) =>
                            formatCategory?.find(
                              (item) =>
                                item?.menuCategory?.name === record?.name
                            )
                          )
                          .map((record) => (
                            <li
                              key={record?.name}
                              className={cx("spyItemClass text-start", {
                                active: activeCategory === record?.name,
                              })}
                              onClick={() =>
                                handleScrollCategory(record?.name)
                              }>
                              {record?.name}
                            </li>
                          ))}
                      </ul>

                      <div
                        id="menuitems"
                        className="ps-1 pe-1"
                        style={{ paddingTop: "0px", display: "flow-root" }}>
                        {formatCategory.map((item, index) => {
                          return (
                            <div
                              key={`category-set-${index}`}
                              className="position-relative">
                              <div
                                id={
                                  item.menuCategory.short
                                    ? item.menuCategory.short
                                    : item.menuCategory.name
                                }
                                className="position-absolute"
                                style={{
                                  top: 10,
                                }}
                              />
                              <div
                                className="category"
                                data-category={
                                  item.menuCategory.short
                                    ? item.menuCategory.short
                                    : item.menuCategory.name
                                }>
                                <div
                                  style={{
                                    position: "sticky",
                                    top: "0px", // hongchuan
                                    zIndex: "1",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    borderRadius: "0px",
                                    ...(item.menuCategory.imageUrl
                                      ? {
                                          minHeight: "40px",
                                        }
                                      : {
                                          height:
                                            item?.subCategoryList?.length > 0
                                              ? "40px"
                                              : "40px",
                                          display: "flex",
                                          justifyContent: "center",
                                        }),
                                  }}
                                  className="category-header flex-center">
                                  {item.menuCategory.imageUrl ? (
                                    <>
                                      <div
                                        className={cx("category-header-image", {
                                          "category-header-image-first":
                                            index === 0,
                                        })}>
                                        <img
                                          src={item.menuCategory.imageUrl}
                                          alt="categoryImage"
                                          style={{
                                            width: "100%",
                                            objectFit: "fill",
                                          }}
                                        />
                                      </div>
                                    </>
                                  ) : (
                                    <div
                                      className="category-title"
                                      style={{
                                        marginBottom: "8px",
                                      }}>
                                      {item.menuCategory.short
                                        ? item.menuCategory.short
                                        : item.menuCategory.name}
                                    </div>
                                  )}
                                  {item?.subCategory?.length > 0 && (
                                    <div className="d-flex align-items-center gap-2 overflow-auto">
                                      {item?.subCategory
                                        ?.filter((record) => record)
                                        ?.map((record) => (
                                          <button
                                            key={record}
                                            className="sub-category-item">
                                            <a href={`#${record}`}>{record}</a>
                                          </button>
                                        ))}
                                    </div>
                                  )}
                                </div>
                                <div className="category-items">
                                  {/* filter menudish based on category_code = category */}
                                  {(item?.subCategoryList?.length > 0
                                    ? item?.subCategoryList
                                    : item?.menus
                                  ).map((menuDishes, idx) => {
                                    return (
                                      <>
                                        {idx === 0 ? (
                                          <div
                                            style={{
                                              height: "0px",
                                              position: "relative",
                                              color: "transparent",
                                              opacity: 0,
                                              ...(item.menuCategory.imageUrl
                                                ? { top: "-80px" }
                                                : { top: "-80px" }),
                                            }}
                                            id={item.menuCategory.name}>
                                            {item.menuCategory.name}
                                          </div>
                                        ) : null}
                                        {menuDishes?.name && (
                                          <div
                                            className="position-relative"
                                            style={{
                                              height: 18,
                                            }}>
                                            <div
                                              id={menuDishes?.name}
                                              className="position-absolute"
                                              style={{
                                                top: -68,
                                              }}
                                            />
                                          </div>
                                        )}
                                        {menuDishes?.menus ? (
                                          menuDishes?.menus?.map((record) => (
                                            <MenuItems
                                              key={`menuItem-${idx}`}
                                              menuDishes={record}
                                              placeOrder={placeOrder}
                                              onDetailOpen={toggleDetailModal}
                                              supaProduct={
                                                supaProducts[
                                                  record.item_category
                                                ]
                                              }
                                            />
                                          ))
                                        ) : (
                                          <MenuItems
                                            key={`menuItem-${idx}`}
                                            menuDishes={menuDishes}
                                            placeOrder={placeOrder}
                                            onDetailOpen={toggleDetailModal}
                                            supaProduct={
                                              supaProducts[
                                                menuDishes.item_category
                                              ]
                                            }
                                          />
                                        )}
                                      </>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  ) : (
                    <div>
                      <img
                        src={`/assets/commonImages/${config?.search}.png`}
                        alt=""
                        className="mt-5"
                      />
                      <div className="d-grid">
                        <label className="no-result-text black-text">
                          No Result
                        </label>
                        <label className="no-result-desc text-black">
                          Sorry we couldn't find any result, try another
                          keyword.
                        </label>
                      </div>
                    </div>
                  )}
                  <Link to="/cart">
                    <button
                      className="checkout-cart-btn w-60 fill-btn sticky-btn position-sticky mb-2 mt-4 me-4 ms-4 pulsing-button"
                      style={{
                        zIndex: 1000,
                      }}>
                      <>
                        <div className="d-flex justify-content-space-evenly align-items-center pt-1 flex-wrap checkout-cart-container">
                          <div className="col">
                            <MdOutlineShoppingCart className="shopping-cart-icon text-white" />
                          </div>
                          <div className="col text-white fw-bold text-start cart-items">
                            Items: {cartItemsCount}
                          </div>
                          <div className="cart-price text-white col">
                            ${" "}
                            {Number(
                              handleConvertPromotionTotal(subTotal)
                            ).toFixed(2)}
                          </div>
                        </div>
                      </>
                    </button>
                  </Link>
                </>
              )}
            </div>

            <Drawer
              open={isBottomOpen}
              onClose={toggleBottom}
              direction="bottom"
              size={300}
              enableOverlay={enableOverlay}>
              <div>
                <label className="d-flex pt-3 ps-3 select-option-text text-uppercase">
                  Select your dining experience
                </label>
                <div className="option-div me-3 ms-3 mt-4 pt-3 pb-3">
                  <div className="dining-select-div">
                    <div className="option-icon">
                      <span className="material-icons pt-3"> restaurant </span>
                    </div>
                    <label className="option-text mt-3 ms-3 text-uppercase">
                      Dine in
                    </label>
                    <Button
                      className="fill-btn cart-btn dining-btn mt-2 position-absolute end-0 me-4 mt-2 text-uppercase"
                      type="submit"
                      onClick={() => selectDining("dinein")}>
                      Select
                    </Button>
                  </div>
                </div>
                <div className="option-div me-3 ms-3 mt-4 pt-3 pb-3">
                  <div className="dining-select-div">
                    <div className="option-icon">
                      <SlBag className="material-icons mt-3 font-18" />
                    </div>
                    <label className="option-text mt-3 ms-3 text-uppercase">
                      Takeaway
                    </label>
                    <Button
                      className="fill-btn cart-btn dining-btn mt-2 position-absolute end-0 me-4 mt-2 text-uppercase"
                      type="submit"
                      onClick={async () => {
                        localStorage.setItem("selectiDineOption", "takeaway");
                        setIsBottomOpen(false);

                        if (config?.enablePreOrder) {
                          openModal();
                        }
                      }}>
                      Select
                    </Button>
                  </div>
                </div>
              </div>
            </Drawer>
          </div>
        </div>
      </div>
      {showDetailModal ? (
        <MenuDetail
          show={showDetailModal}
          onHide={toggleDetailModal}
          data={selectedItem}
          placeOrder={placeOrder}
          supaProduct={supaProducts[selectedItem.item_category]}
        />
      ) : null}
      {showCustomizeModal ? (
        <CustomizeModal
          showModal={showCustomizeModal}
          setShowModal={setShowCustomizeModal}
          showModalData={showModalData}
          onSuccessSubmit={handleCustomModalSuccessSubmit}
        />
      ) : null}
      {showUpsellingModal ? (
        <UpsellingModal
          show={showUpsellingModal}
          handleClose={() => setShowUpsellingModal(false)}
          recommendationItems={upsellingItems}
          clearUpsellingState={clearState}
        />
      ) : null}
      <WelcomeBanner />
    </>
  );
}
export default Home;
