import { getCategoryByName } from "../services/supabase/category";
import { getOutletByName } from "../services/supabase/outlet";
import {
  getProductByIds,
  getProductByPOSItemNo,
} from "../services/supabase/product";
import { getUpsellingByTargetId } from "../services/supabase/upselling";
import getBrandImage from "./getBrandImage";

const getUpsellingItems = async (categoryCode, posItemNo) => {
  try {
    const catResp = await getCategoryByName(categoryCode);
    const posProduct = await getProductByPOSItemNo(
      process.env.REACT_APP_MODE,
      posItemNo
    );

    if (
      (catResp.error || catResp.data.length === 0) &&
      (posProduct.error || posProduct.data.length === 0)
    ) {
      return {
        hasUpsellingItems: false,
        items: [],
      };
    }

    const outletResp = await getOutletByName(localStorage.getItem("storeName"));
    console.log("outlet", outletResp);

    if (outletResp.error || outletResp.data.length === 0) {
      return {
        hasUpsellingItems: false,
        items: [],
      };
    }

    const upsellingResp = await getUpsellingByTargetId(
      catResp?.data?.[0]?.id,
      posProduct?.data?.[0]?.id,
      outletResp?.data?.[0]?.id
    );

    if (upsellingResp.error || upsellingResp.data.length === 0) {
      return {
        hasUpsellingItems: false,
        items: [],
      };
    }

    const productsResp = await getProductByIds(
      upsellingResp.data[0].upsell_items
    );

    if (productsResp.error || productsResp.data.length === 0) {
      return {
        hasUpsellingItems: false,
        items: [],
      };
    }

    const brandLogo = await getBrandImage();
    const mappedItems = productsResp.data.map((item) => ({
      id: item.pos_item_no,
      imageUrl: item.image_url !== null ? item.image_url : brandLogo.data,
    }));

    return {
      hasUpsellingItems: true,
      items: mappedItems,
    };
  } catch (error) {
    console.error("Error in getUpsellingItems:", error);
    return {
      hasUpsellingItems: false,
      items: [],
    };
  }
};

export default getUpsellingItems;
