import { STORAGEKEY } from "../../constants";
import { apiCall } from "../../lib/supabase";

export const getBrandConfig = async () => {
  const response = await apiCall.functions.invoke(
    `brand-config?brandName=${
      process.env.REACT_APP_MODE
    }&storeName=${localStorage.getItem(STORAGEKEY.STORE_NAME)}`,
    {
      body: {
        name: "Functions",
      },
    }
  );

  return response;
};
