import React, { useEffect, useState } from "react";
import {
  storeMenuButton,
  storeMenuItems,
  storeStockList,
} from "../../services/tableQRServices";
import _ from "lodash";
import Carousel from "react-multi-carousel";
import { addToCart } from "../../redux/cartSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import moment from "moment";
import { CustomizeModal } from "../../pages/home/CustomizeModal";

const UpsellingItems = ({ sliderdata, index, placeOrder }) => {
  const isSoldOut = React.useMemo(() => {
    return (
      sliderdata.is_soldout === "Y" ||
      !sliderdata.is_soldout ||
      sliderdata.is_emenu_disable === "Y" ||
      !sliderdata.is_emenu_disable ||
      (sliderdata.is_avl_limit_check === "Y" && sliderdata.bal_qty === 0) ||
      !sliderdata.is_avl_limit_check
    );
  }, []);

  return (
    <div className="item relative px-1" data-value={index}>
      <img
        src={sliderdata.supabaseImageUrl}
        style={{ height: "100%", width: "100%", objectFit: "cover" }}
        className="popular-img"
        alt=""
      />
      <div className="d-grid text-start">
        <label className="recom-text mt-1 text-uppercase mb-2">
          {sliderdata.item_desc}
        </label>
        <label className="recom-price">
          $
          {Number(
            sliderdata?.selling_uom_dtls[0]?.price_dtls[0]?.dine_in_price
          ).toFixed(2)}
        </label>
        <button
          className="border-btn add-btn mt-3"
          onClick={() => !isSoldOut && placeOrder(sliderdata)}
          disabled={isSoldOut}
          style={{ ...(isSoldOut && { opacity: "0.5" }) }}
        >
          {isSoldOut ? "Sold Out" : "ADD"}
        </button>
      </div>
    </div>
  );
};

function UpsellingContent({
  responsive,
  afterPlaceOrder,
  recommendationItems,
}) {
  const [showCustomizeModal, setShowCustomizeModal] = useState(false);
  const [showModalData, setShowModalData] = useState([]);
  const dispatcher = useDispatch();

  const placeOrder = (dishDetail) => {
    if (dishDetail.itemmaster_menutype_grpdtls === "") {
      dispatcher(
        addToCart({
          id: dishDetail.item_no,
          addOnValues: [],
          item: dishDetail,
          qty: 1,
          price: dishDetail.selling_uom_dtls[0]?.price_dtls[0]?.dine_in_price,
        })
      );
      toast.success(`${dishDetail.item_name}  ADDED TO CART`, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
      if (afterPlaceOrder) {
        afterPlaceOrder();
      }
    } else {
      const customDetails = {
        item: dishDetail,
      };
      setShowModalData(customDetails);
      setShowCustomizeModal(true);
    }
  };

  const [gochiMenuItems, setGochiMenuItems] = useState([]);

  const itemsListAndCategory = () => {
    storeStockList()
      .then((res) => {
        const result = res?.data?.data[0]?.output;
        const resultParse = JSON.parse(result);
        let categoryCodes = [];
        for (const category of resultParse.filter(
          (category) => category.avl_type === "C"
        )) {
          categoryCodes.push(category.item_category);
        }
        let resultItemsParsed = resultParse.filter(
          (category) => category.avl_type === "I"
        );
        menuButtonFunction(categoryCodes, resultItemsParsed, resultParse);
      })
      .catch((err) => {});
  };

  const menuButtonFunction = (
    categoryCodes,
    resultItemsParsed,
    resultParse
  ) => {
    storeMenuButton()
      .then((res) => {
        const categoryMenuButton = res?.data?.data[0]?.output;
        let categoryMainMenuButton = categoryMenuButton.filter(
          (menuItem) => menuItem.root_category_code === "MAIN"
        );
        if (categoryMainMenuButton && categoryMainMenuButton.length > 0) {
          categoryMainMenuButton = categoryMainMenuButton[0];
          let categoriesIndex = categoryMainMenuButton.category;
          // ? Sort on sequences and filter on start and end time
          categoriesIndex = categoriesIndex
            .sort((a, b) => a.course_seq - b.course_seq)
            .filter(
              (category) =>
                category.start_time <= moment().format("HH:mm:ss") &&
                category.end_time > moment().format("HH:mm:ss")
            );

          categoryCodes = [];
          for (const category of categoriesIndex) {
            categoryCodes.push(category.category_code);
          }
          let menuItemsArray = [];
          for (const category of categoryCodes) {
            const catItem = categoryMenuButton.filter(
              (catItems) => catItems.root_category_code === category
            );
            if (
              catItem &&
              catItem.length > 0 &&
              catItem[0].items &&
              catItem[0].items.length > 0
            ) {
              menuItemsArray.push(...catItem[0].items);
            }
          }
          // ? Intersect items from stock and button api
          let intersectCategory = _.intersectionBy(
            resultItemsParsed,
            menuItemsArray,
            (obj) => obj.item_category || obj.item_no
          );
          // ? Intersect items from with intersected items and button api items
          intersectCategory = Object.values(
            _.merge(
              _.keyBy(intersectCategory, "item_category"),
              _.keyBy(menuItemsArray, "item_no")
            )
          )
            .filter(
              (item) =>
                item.is_soldout === "N" &&
                item.is_emenu_disable === "N" &&
                item.start_time <= moment().format("HH:mm:ss") &&
                item.end_time > moment().format("HH:mm:ss")
            )
            .filter((item) => {
              const recommendationIds = recommendationItems.map(
                (item) => item.id
              );
              if (recommendationItems.length > 0) {
                if (recommendationIds.includes(item.item_no)) {
                  return true;
                } else {
                  return false;
                }
              } else {
                return true;
              }
            })
            .map((item) => {
              const newImageUrl = recommendationItems.find(
                (recomendItem) => recomendItem.id === item.item_no
              );
              return {
                ...item,
                supabaseImageUrl: newImageUrl ? newImageUrl.imageUrl : null,
              };
            });

          menuItemsFunction(intersectCategory, categoryCodes, resultParse);
        }
      })
      .catch(() => {});
  };

  const menuItemsFunction = (intersectCategory, categoryCodes, resultParse) => {
    storeMenuItems()
      .then((res) => {
        const result = res?.data?.data[0]?.output;
        const filterItemMaster = result.filter(
          (item) =>
            item.itemmaster_menutypedtls &&
            item.itemmaster_menutypedtls.length > 0
        );

        const stockedCheckedItem = [];
        if (filterItemMaster && filterItemMaster.length > 0) {
          for (const itemMaster of filterItemMaster) {
            let itemmaster_menutypedtls = [];
            for (const typeDtls of itemMaster.itemmaster_menutypedtls) {
              const modifierQty = resultParse.filter(
                (itemCategory) => itemCategory.item_no === typeDtls.citem_no
              );

              if (modifierQty && modifierQty.length > 0) {
                itemmaster_menutypedtls.push({
                  ...typeDtls,
                  is_soldout: modifierQty[0].is_soldout,
                  is_emenu_disable: modifierQty[0].is_emenu_disable,
                  is_avl_limit_check: modifierQty[0].is_avl_limit_check,
                  bal_qty: modifierQty[0].bal_qty,
                });
              } else {
                itemmaster_menutypedtls.push({ ...typeDtls });
              }
            }

            stockedCheckedItem.push({
              ...itemMaster,
              itemmaster_menutypedtls,
            });
          }
        }
        const filterNoItemMaster = result.filter(
          (item) =>
            !item.itemmaster_menutypedtls ||
            item.itemmaster_menutypedtls.length <= 0
        );

        let intersectItems = _.intersectionBy(
          intersectCategory,
          [...filterNoItemMaster, ...stockedCheckedItem],
          (obj) => obj.item_category || obj.item_no
        ).filter((item) => item.is_active);

        intersectItems = Object.values(
          _.merge(
            _.keyBy(intersectCategory, "item_category"),
            _.keyBy([...filterNoItemMaster, ...stockedCheckedItem], "item_no")
          )
        )
          .filter(
            (item) =>
              item.is_active && categoryCodes.includes(item.category_code)
          )
          .filter((item) => item.is_emenu_disable === "N");

        const sortAlphabetically = intersectItems.sort((a, b) =>
          a.item_desc.localeCompare(b.item_desc)
        );

        const sortByCategory = sortAlphabetically.sort(
          (a, b) =>
            categoryCodes.indexOf(a.category_code) -
            categoryCodes.indexOf(b.category_code)
        );

        setGochiMenuItems(sortByCategory);
      })

      .catch((err) => {});
  };

  useEffect(() => {
    itemsListAndCategory();
  }, []);
  const handleCustomModalSuccessSubmit = async (submittedItem) => {
    if (afterPlaceOrder) {
      afterPlaceOrder();
    }
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={500000000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Carousel
        showDots={false}
        responsive={responsive}
        ssr={false} // means to render carousel on server-side.
        infinite={false}
        autoPlaySpeed={1000}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={500}
        className="flex gap-2"
        containerClass="carousel-container flex gap-2"
        // removeArrowOnDeviceType={["tablet", "mobile"]}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        {gochiMenuItems &&
          gochiMenuItems.map((sliderdata, index) => {
            return (
              <UpsellingItems
                key={index}
                sliderdata={sliderdata}
                index={index}
                placeOrder={placeOrder}
              />
            );
          })}
      </Carousel>
      {showCustomizeModal ? (
        <CustomizeModal
          showModal={showCustomizeModal}
          setShowModal={setShowCustomizeModal}
          showModalData={showModalData}
          onSuccessSubmit={handleCustomModalSuccessSubmit}
          redirectAfterSubmit={false}
        />
      ) : null}
    </>
  );
}

export default UpsellingContent;
