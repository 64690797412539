import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

const initialState = {
  cart: [],
  total: 0,
  sales_no: "",
  order_details: [],
  isGSTInclusive: false,
  isGST: false,
  isServiceCharge: false,
  taxValue: 0,
  taxList: [],
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const itemInCart = state.cart.find(
        (item) =>
          item.id === action.payload.id &&
          item.uuid === action.payload.uuid &&
          item.addOnValues.length === action.payload.addOnValues.length &&
          _.isEqual(
            _.map(item.addOnValues, "item_no"),
            _.map(action.payload.addOnValues, "item_no")
          )
      );

      if (itemInCart && !action?.payload?.isNew) {
        itemInCart.item = action.payload.item;
        itemInCart.qty++;
        state.total += action.payload.price;
      } else {
        state.cart.push({ ...action.payload, uuid: uuidv4() });
        state.total += action.payload.price * action.payload.qty;
      }
    },
    updateToCart: (state, action) => {
      const removeItem = state.cart.find((item) => {
        return item.uuid === action.payload.uuid;
      });
      state.total -= removeItem.qty * removeItem.price;

      const itemIndex = state.cart.findIndex(
        (item) => item.uuid === action.payload.uuid
      );
      if (itemIndex !== -1) {
        const updatedItem = { ...state.cart[itemIndex], ...action.payload };
        state.cart[itemIndex] = updatedItem;
        state.total += action.payload.price * action.payload.qty;
      }
    },
    incrementQuantity: (state, action) => {
      if (action.payload.isNew) {
        state.cart.push({ ...action.payload, uuid: uuidv4(), qty: 1 });
        state.total += action.payload.price;
      } else {
        const item = state.cart.find(
          (item) => item.uuid === action.payload.uuid
        );

        if (action.payload.item) {
          item.item = action.payload.item;
        }

        item.qty++;

        state.total += item.price;
      }
    },
    decrementQuantity: (state, action) => {
      const item = state.cart.find((item) => item.uuid === action.payload.uuid);

      if (item.qty === 1) {
        const removeItem = state.cart.filter(
          (item) => item.uuid !== action.payload.uuid
        );
        state.total -= item.price;
        state.cart = removeItem;
      } else {
        if (action.payload.item) {
          item.item = action.payload.item;
        }

        item.qty--;
        state.total -= item.price;
      }
    },
    removeItem: (state, action) => {
      const removeItem = state.cart.filter(
        (item) => item.uuid !== action.payload
      );
      state.total -= removeItem.price;
      state.cart = removeItem;
    },
    deleteCartItem: (state, action) => {
      const item = state.cart.find((item) => item.uuid === action.payload);
      const removePrice = item.price * item.qty;
      const removedCartItem = state.cart.filter(
        (i) => i.uuid !== action.payload
      );
      state.total -= removePrice;
      state.cart = removedCartItem;
    },
    placeOrderSuccess: (state, action) => {
      state.order_details.push({
        items: state.cart,
        subTotal: state.total,
        time: new Date(),
        ...action.payload,
      });
      state.cart = [];
      state.total = 0;
    },
    clearCart: (state, action) => {
      state.cart = [];
      state.total = 0;
      state.order_details = [];
      state.sales_no = "";
    },
    addSalesNo: (state, action) => {
      state.sales_no = action.payload;
    },
    resetState: (state) => {
      state.cart = initialState.cart;
      state.total = initialState.total;
      state.sales_no = initialState.sales_no;
      state.order_details = initialState.order_details;
      state.isGSTInclusive = initialState.isGSTInclusive;
      state.isGST = initialState.isGST;
      state.isServiceCharge = initialState.isServiceCharge;
      state.taxValue = initialState.taxValue;
      state.taxList = initialState.taxList;
    },
    setTotal: (state, action) => {
      state.total = action.payload.reduce(
        (a, b) => (a += b?.qty * b?.price),
        0
      );
    },
    setIsGSTInclusive: (state, action) => {
      state.isGSTInclusive = action.payload;
    },
    setIsServiceCharge: (state, action) => {
      state.isServiceCharge = action.payload;
    },
    setIsGST: (state, action) => {
      state.isGST = action.payload;
    },
    setTaxValue: (state, action) => {
      state.taxValue = action.payload;
    },
    setTaxList: (state, action) => {
      let newData = [];

      action.payload?.forEach((record) => {
        const findItem = newData?.find(
          (item) => item?.taxAmt === record?.tax_value
        );

        if (!findItem) {
          newData.push({
            taxName:
              record?.tax_name === "ZERO" || !record?.tax_name
                ? ""
                : `GST ${record?.tax_value} ${
                    record?.is_absorbtax ? `(Inclusive)` : ""
                  }`,
            taxAmt: record?.tax_value,
            isGSTInclusive: record?.is_absorbtax ? true : false,
          });
        }
      });

      newData = newData?.filter((record) => record?.taxName);

      state.taxList = newData;
    },
  },
});

export const cartReducer = cartSlice.reducer;
export const {
  addToCart,
  updateToCart,
  incrementQuantity,
  decrementQuantity,
  removeItem,
  deleteCartItem,
  placeOrderSuccess,
  addSalesNo,
  resetState,
  setTotal,
  setIsGSTInclusive,
  setIsGST,
  setIsServiceCharge,
  setTaxValue,
  setTaxList,
} = cartSlice.actions;
