import React, { useEffect, useState } from "react";

const checkIfImageExists = (url, callback) => {
  const img = new Image();
  img.src = url;

  if (img.complete) {
    callback(true);
  } else {
    img.onload = () => {
      callback(true);
    };

    img.onerror = () => {
      callback(false);
    };
  }
};

const RecommendationItems = ({
  sliderdata,
  index,
  placeOrder,
  supaProducts,
  onDispatchCartTax,
}) => {
  const defaultImage = `/assets/${process.env.REACT_APP_MODE}/logo/logo.png`;

  const [imageURL, setImageURL] = useState(defaultImage);

  const findProduct = supaProducts?.[sliderdata?.item_no];

  const originalImageUrl = findProduct?.image_url;

  useEffect(() => {
    checkIfImageExists(originalImageUrl, (exists) => {
      if (exists) {
        setImageURL(originalImageUrl);
      } else {
        setImageURL(defaultImage);
      }
    });
  }, [defaultImage, originalImageUrl, sliderdata.item_no]);

  useEffect(() => {
    if (sliderdata) {
      onDispatchCartTax?.(sliderdata);
    }
  }, [sliderdata]);

  return (
    <div className="item" data-value={index}>
      {Object.keys(supaProducts)?.length > 0 && imageURL && (
        <img src={imageURL} className="popular-img" alt="" />
      )}
      <div className="d-grid text-start">
        <label className="recom-text mt-1 text-uppercase text-truncate">
          {sliderdata.item_desc}
        </label>
        <label className="recom-price">
          $
          {Number(
            sliderdata?.selling_uom_dtls[0]?.price_dtls[0]?.dine_in_price
          ).toFixed(2)}
        </label>
      </div>
      {sliderdata.is_soldout === "Y" ||
      sliderdata.is_emenu_disable === "Y" ||
      (sliderdata.is_avl_limit_check === "Y" && sliderdata.bal_qty === 0) ? (
        <button className="sold-out-btn mb-2 mt-4" disabled>
          SOLD OUT
        </button>
      ) : (
        <button
          className="border-btn add-btn mt-3"
          type="submit"
          onClick={() => placeOrder(sliderdata)}>
          ADD
        </button>
      )}
    </div>
  );
};

export default RecommendationItems;
