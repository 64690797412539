import React from "react";
import { BsDashLg, BsPlusLg } from "react-icons/bs";
import cx from "classnames";

import "./styles.css";

const FreeItemByValueItem = ({ formData, data, onIncrease, onDecrease }) => {
  const defaultImage = `/assets/${process.env.REACT_APP_MODE}/logo/logo.png`;
  const formatQty = formData?.qty || 0;

  return (
    <div className="free-item-by-value-item">
      <div className="free-item-by-value-item-left-side">
        <p>{data?.item_desc}</p>
        <p>Free</p>
      </div>
      <img
        src={defaultImage}
        alt=""
        width="100"
        style={{
          marginBottom: 40,
        }}
      />
      <div className="free-item-by-value-item-right-side">
        <BsDashLg
          className={cx("qty-class", {
            "qty-class-disable": !formData?.qty,
          })}
          onClick={() => formData?.qty && onDecrease?.(data)}
        />
        <div className="free-item-by-value-item-right-side-qty">
          <span>{formatQty}</span>
        </div>
        <BsPlusLg
          className={cx("qty-class", {
            "qty-class-disable": data?.qty <= formatQty,
          })}
          onClick={() => data?.qty > formatQty && onIncrease?.(data)}
        />
      </div>
    </div>
  );
};

export default FreeItemByValueItem;
