import React, { useState } from "react";
import { HiArrowSmLeft } from "react-icons/hi";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineSearch } from "react-icons/ai";
import { MdOutlineLocalDining, MdOutlineGrading } from "react-icons/md";
import { Offcanvas } from "react-bootstrap";
import { BiChevronRight } from "react-icons/bi";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { TbRefreshDot } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { RiCloseCircleFill } from "react-icons/ri";
import { Collapse, Form } from "react-bootstrap";
import { IoIosArrowBack } from "react-icons/io";
import useBrandConfig from "../hooks/useBrandConfig";
import { useSelector } from "react-redux";

function Header(props) {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const closeSidebar = () => setShow(false);
  const showSidebar = () => setShow(true);

  const { config } = useBrandConfig();

  // function to clear dining option in local storage
  const clearDiningOption = () => {
    localStorage.removeItem("selectiDineOption");
    // close the side bar
    navigate("/home", { replace: true });
    closeSidebar();
    props.openDiningOption();
    // navigate to home page with a reload
  };

  const cartItems = useSelector((state) => state.cart.cart);

  const cartItemsCount = cartItems.reduce((acc, item) => acc + item.qty, 0);

  return (
    <>
      <div className="header">
        <div
          className={
            props.title === "home"
              ? "d-flex w-100 top-0 start-0 position-sticky header-cls justify-content-center align-items-center"
              : "d-flex w-100 top-0 start-0 position-sticky header-inner-cls"
          }>
          {props.title === "home" ? (
            <>
              {!props.showSearchBar ? (
                <>
                  <div className="d-flex top-0 start-0 w-100 position-absolute side-menu justify-content-between align-items-center">
                    <div className="ps-4 pe-4">
                      <GiHamburgerMenu
                        className="pointer-cls header-icon"
                        onClick={showSidebar}
                        size={32}
                      />
                      <Offcanvas
                        backdrop="false"
                        show={show}
                        onHide={closeSidebar}>
                        <Offcanvas.Header
                          closeButton
                          closeVariant="white"
                          className="position-absolute w-100 side-menu-header d-flex justify-content-between align-items-center p-3">
                          <Offcanvas.Title className="ps-1">
                            <img
                              src={`/assets/${process.env.REACT_APP_MODE}/logo/logo2.png`}
                              alt="Logo"
                              className="img-size"
                            />
                          </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                          <div className="position-absolute w-100 side-menu-body pointer-cls">
                            <Link to="/home" onClick={closeSidebar}>
                              <div className="d-flex ps-4 pe-4 w-100">
                                <div>
                                  <MdOutlineLocalDining className="material-icons" />
                                </div>
                                <div className="ps-4 menu-text">Menu</div>
                                <div className="position-absolute end-0 pe-4">
                                  <BiChevronRight className="right-arrow" />
                                </div>
                              </div>
                            </Link>
                            <hr className="ms-4 me-4 bttom-border" />
                            <Link to="/order-history">
                              <div className="d-flex ps-4 pe-4 w-100">
                                <div>
                                  <MdOutlineGrading className="material-icons" />
                                </div>
                                <div className="ps-4 menu-text">
                                  Order History
                                </div>
                                <div className="position-absolute end-0 pe-4">
                                  <BiChevronRight className="right-arrow" />
                                </div>
                              </div>
                            </Link>
                            {/* <hr className="ms-4 me-4 bttom-border" />
                            <Link to="/my-orders">
                              <div className="d-flex ps-4 pe-4 w-100">
                                <div>
                                  <MdOutlineGrading className="material-icons" />
                                </div>
                                <div className="ps-4 menu-text">My Orders</div>
                                <div className="position-absolute end-0 pe-4">
                                  <BiChevronRight className="right-arrow" />
                                </div>
                              </div>
                            </Link> */}
                            <>
                              {/*

                          On clicking the right arrow, clear the dine in option in the local storage and redirect to the home page

                          */}
                              {config?.serviceMode === "DineInAndTakeAway" && (
                                <>
                                  <hr className="ms-4 me-4 bttom-border" />
                                  <div className="d-flex ps-4 pe-4 w-100">
                                    <div>
                                      <TbRefreshDot className="material-icons" />
                                    </div>
                                    <div
                                      className="ps-4 menu-text"
                                      onClick={clearDiningOption}>
                                      Change Dining Option
                                    </div>
                                    <div className="position-absolute end-0 pe-4">
                                      <BiChevronRight className="right-arrow" />
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                            {/* {process.env.REACT_APP_MODE === "pavilion" ? (
                            <>
                              <hr className="ms-4 me-4 bttom-border" />
                              <div className="d-flex ps-4 pe-4 w-100">
                                <div>
                                  <TbRefreshDot className="material-icons" />
                                </div>
                                <div className="ps-4 menu-text">
                                  Change Dining Option
                                </div>
                                <div className="position-absolute end-0 pe-4">
                                  <BiChevronRight className="right-arrow" />
                                </div>
                              </div>
                            </>
                          ) : (
                            ""
                          )} */}
                          </div>
                        </Offcanvas.Body>
                      </Offcanvas>
                    </div>
                    <div className="d-flex align-items-center justify-content-center flex-grow-1">
                      <img
                        src={`/assets/${process.env.REACT_APP_MODE}/logo/logo2.png`}
                        alt="Logo"
                        className="img-size"
                      />
                    </div>
                    <div className="text-end">
                      <Link to="/cart">
                        <div className="d-flex ps-4 pe-4 w-100">
                          <div>
                            <AiOutlineShoppingCart
                              className="material-icons me-2 shopping-cart-icon"
                              size={32}
                            />
                          </div>
                          {cartItemsCount > 0 && (
                            <div className="position-absolute end-0 pe-4">
                              <div className="cart-badge">{cartItemsCount}</div>
                            </div>
                          )}
                        </div>
                      </Link>
                    </div>
                  </div>
                </>
              ) : (
                <Collapse in="true">
                  <div className="d-flex align-items-center search-form mb-4 mt-2 me-3 ms-3">
                    <AiOutlineSearch className="input-search-icon ms-2" />
                    <input
                      placeholder="Search Menus"
                      className="search-input font-family-class bg-white"
                      onChange={(event) =>
                        props.searchFunction(event.target.value)
                      }
                    />
                    <RiCloseCircleFill
                      onClick={() => {
                        props.handleSearchClick();
                        props.searchFunction("");
                      }}
                      className="close-search position-fixed end-0 me-3"
                    />
                  </div>
                </Collapse>
              )}
            </>
          ) : (
            <>
              <div className="flex-grow-1">
                <div
                  className="position-absolute back-arrow"
                  onClick={() => navigate(-1)}>
                  <IoIosArrowBack size={32} />
                </div>
                <div className="header-title text-uppercase">{props.title}</div>
              </div>
            </>
          )}
        </div>
        {/* <div className="header-status w-100 d-flex justify-content-between">
            <div className="mx-auto max-w-40 text-uppercase"><span>{localStorage.getItem("selectiDineOption") ? "Dine In" : "Take Away"}</span></div>
            <div className="mx-auto max-w-40 text-uppercase"><span>Table No: {localStorage.getItem("tableNo")}</span></div>
        </div> */}
      </div>
    </>
  );
}

export default Header;
