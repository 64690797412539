import React, { Fragment } from "react";
import CustomizeItem from "../../pages/home/CustomizeItem";
import { Form } from "react-bootstrap";

const CustomizeForm = ({
  price,
  getMenuTypeGrouped,
  customizeData,
  formik,
  getQtyByModifier,
  menuTypeGroupIndex,
  toggleComment,
  getMenuType,
  handleRadioButtonSelected,
  stepperMenuIcon,
  defaultIcon,
  config,
  usingPriceHeader,
  selectedDineOption,
}) => {
  return (
    <>
      <div>
        {usingPriceHeader && (
          <div className="d-flex justify-content-end">
            {/* <BsFillCircleFill /> */}
            <label className="customize-header">S{price.formatted}</label>
          </div>
        )}

        <div>
          {getMenuTypeGrouped(customizeData).map((menuTypeGroup, i) => (
            <Fragment key={i}>
              <CustomizeItem
                menuTypeGroup={menuTypeGroup}
                formik={formik}
                getQtyByModifier={getQtyByModifier}
                index={i}
                menuTypeGroupIndex={menuTypeGroupIndex}
                toggleComment={toggleComment}
                getMenuType={getMenuType}
                customizeData={customizeData}
                handleRadioButtonSelected={handleRadioButtonSelected}
                selectedDineOption={selectedDineOption}
                firstMenuTypeGroup={getMenuTypeGrouped(customizeData)?.[0]}
                stepperMenuIcon={stepperMenuIcon}
                defaultIcon={defaultIcon}
              />
            </Fragment>
          ))}
        </div>
      </div>
      {config?.enableRemarks && (
        <div>
          <span className="mt-2 text-uppercase fw-bold">Remarks / To Note</span>
          <Form.Group className="mb-3">
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Enter remarks"
              name="remarks"
              onChange={formik.handleChange}
              value={formik.values.remarks}
              maxLength={100}
              className="public-sans-font line-height-25 font-14 line-height-20 black-text"
            />
          </Form.Group>
        </div>
      )}
    </>
  );
};

export default CustomizeForm;
