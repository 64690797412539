import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import cx from "classnames";
import {
  BsFillCaretUpFill,
  BsFillCaretDownFill,
  BsFillCheckCircleFill,
  BsDashLg,
  BsPlusLg,
} from "react-icons/bs";
import CustomizeMinQtyOrder from "./CustomizeMinQtyOrder";
import { Form } from "react-bootstrap";
import ButtonOption from "../../component/ButtonOption/ButtonOption";
import useBrandConfig from "../../hooks/useBrandConfig";

const CustomizeItem = ({
  menuTypeGroup,
  formik,
  getQtyByModifier,
  index,
  menuTypeGroupIndex,
  toggleComment,
  getMenuType,
  customizeData,
  handleRadioButtonSelected,
  selectedDineOption,
  firstMenuTypeGroup,
  stepperMenuIcon,
  defaultIcon,
}) => {
  const [isFirstOpen, setIsFirstOpen] = useState(false);
  const [breakpointQty, setBreakpointQty] = useState(false);

  const qtyRef = useRef();
  const nameRef = useRef();
  const { config } = useBrandConfig();

  useEffect(() => {
    if (
      menuTypeGroupIndex === menuTypeGroup.item_menutype_grpdtls &&
      !config?.openAllOptions
    ) {
      setIsFirstOpen(true);
    }
  }, [
    config?.openAllOptions,
    menuTypeGroup.item_menutype_grpdtls,
    menuTypeGroupIndex,
  ]);

  useEffect(() => {
    if (
      !config?.openAllOptions &&
      isFirstOpen &&
      menuTypeGroup?.is_optional !== "Y"
    ) {
      formik?.validateField(menuTypeGroup?.modifier_name);
    }
  }, [
    isFirstOpen,
    config?.openAllOptions,
    menuTypeGroup?.is_optional,
    formik.values?.[menuTypeGroup?.modifier_name],
  ]);

  const incrementCounter = (modifierName, citemName) => {
    formik.setValues({
      ...formik.values,
      [modifierName]: [...formik.values[modifierName], citemName],
    });
  };

  const decrementCounter = (modifierName, citemName) => {
    const allElExceptTarget = formik.values[modifierName].filter(
      (it) => it !== citemName
    );

    const targetEl = formik.values[modifierName].filter(
      (it) => it === citemName
    );

    if (targetEl.length > 1) {
      targetEl.shift();
    }

    formik.setValues({
      ...formik.values,
      [modifierName]: [...allElExceptTarget, ...targetEl],
    });
  };

  const getQtyItem = (modifierName, citemName) =>
    formik.values[modifierName].filter((it) => it === citemName).length;

  const getGroupQty = (modifierName, exceptCitemName) =>
    Array.isArray(formik.values[modifierName])
      ? formik.values[modifierName].filter((it) => it !== exceptCitemName)
          .length
      : 1;

  const handleResizeQty = () => {
    const windowWidth = window.innerWidth;

    if (
      windowWidth < 500 ||
      windowWidth - 160 - nameRef?.current?.clientWidth <
        qtyRef?.current?.clientWidth
    ) {
      setBreakpointQty(true);
    } else {
      setBreakpointQty(false);
    }
  };

  useEffect(() => {
    handleResizeQty();

    window.addEventListener("resize", handleResizeQty);

    return () => {
      window.removeEventListener("resize", handleResizeQty);
    };
  }, []);

  const temperatureSingleOptionList = getMenuType(
    customizeData,
    menuTypeGroup.modifier_name
  ).filter((record) =>
    record?.modifier_name === "TEMPERATURE" ||
    record?.modifier_name === "HOT/ICE" ||
    record?.modifier_name === "ICE LEVEL"
      ? record?.citem_name?.includes("(H)") ||
        record?.citem_name?.includes("(I)")
        ? formik?.values?.[firstMenuTypeGroup?.modifier_name]?.includes("H-")
          ? record?.citem_name?.includes("(H)")
          : record?.citem_name?.includes("(I)")
        : true
      : true
  );
  const temperatureMultipleOptionList =
    customizeData.item?.itemmaster_menutypedtls
      .filter(
        (customItems) =>
          customItems.modifier_name === menuTypeGroup.modifier_name
      )
      .filter((record) => record?.is_emenu_disable !== "Y")
      .filter((record) =>
        record?.modifier_name === "TEMPERATURE" ||
        record?.modifier_name === "HOT/ICE" ||
        record?.modifier_name === "ICE LEVEL"
          ? record?.citem_name?.includes("(H)") ||
            record?.citem_name?.includes("(I)")
            ? formik?.values?.[firstMenuTypeGroup?.modifier_name]?.includes(
                "H-"
              )
              ? record?.citem_name?.includes("(H)")
              : record?.citem_name?.includes("(I)")
            : true
          : true
      );

  return (
    ((menuTypeGroup.max_qty === 1 && temperatureSingleOptionList?.length > 0) ||
      (menuTypeGroup.max_qty !== 1 &&
        temperatureMultipleOptionList?.length > 0)) && (
      <>
        <div
          style={
            config?.openAllOptions
              ? {}
              : !isFirstOpen || formik.errors?.[menuTypeGroup?.modifier_name]
              ? { opacity: "0.6" }
              : {}
          }
          className={cx("category-option d-flex justify-content-between mb-1", {
            selected:
              getQtyByModifier(menuTypeGroup.modifier_name) > 0 &&
              !formik.errors[menuTypeGroup.modifier_name],
          })}>
          <div className="ms-3 pt-1 d-flex flex-row justify-content-center align-items-center gap-1 customize-item">
            {!config?.openAllOptions &&
              (!isFirstOpen || formik.errors?.[menuTypeGroup?.modifier_name] ? (
                <div className="badge-number-qty">{index + 1}</div>
              ) : (
                <BsFillCheckCircleFill style={{ fontSize: "20px" }} />
              ))}
            {config?.openAllOptions && (
              <BsFillCheckCircleFill style={{ fontSize: "20px" }} />
            )}
            <label className="ms-1 text-white font-16 fw-normal menu-type-group-title">
              {menuTypeGroup.modifier_name}
            </label>
          </div>
          {!config?.openAllOptions &&
            menuTypeGroupIndex !== undefined &&
            (menuTypeGroupIndex === menuTypeGroup.item_menutype_grpdtls ? (
              <BsFillCaretDownFill
                className="me-3 pt-1"
                style={{ fontSize: "33px" }}
                onClick={() =>
                  toggleComment?.(menuTypeGroup.item_menutype_grpdtls, "up")
                }
              />
            ) : (
              <BsFillCaretUpFill
                className="me-3 pt-1"
                style={{ fontSize: "33px" }}
                onClick={() =>
                  toggleComment?.(menuTypeGroup.item_menutype_grpdtls, "down")
                }
              />
            ))}
        </div>
        {menuTypeGroupIndex === menuTypeGroup.item_menutype_grpdtls ||
        config?.openAllOptions ? (
          <>
            {menuTypeGroup.max_qty === 1
              ? temperatureSingleOptionList?.length > 0 && (
                  <>
                    <div className="mb-3">
                      <label className="mt-2 font-16 fw-normal">
                        Choose max {menuTypeGroup.max_qty}
                      </label>
                      {temperatureSingleOptionList.map((menuType, index) => (
                        <Fragment key={index}>
                          <CustomizeMinQtyOrder
                            index={index}
                            menuType={menuType}
                            handleRadioButtonSelected={
                              handleRadioButtonSelected
                            }
                            menuTypeGroup={menuTypeGroup}
                            formik={formik}
                            selectedDineOption={selectedDineOption}
                            isDefaultSelect={
                              // getMenuType(
                              //   customizeData,
                              //   menuTypeGroup.modifier_name
                              // )?.length === 1
                              // defaultSelectModifier(menuType)
                              menuType.is_default === "Y"
                            }
                            firstMenuTypeGroup={firstMenuTypeGroup}
                            stepperMenuIcon={stepperMenuIcon}
                            defaultIcon={defaultIcon}
                          />
                        </Fragment>
                      ))}
                    </div>
                  </>
                )
              : temperatureMultipleOptionList?.length > 0 && (
                  <>
                    <div className="mb-3">
                      <label className="mt-2 font-16 fw-normal">
                        {menuTypeGroup.max_qty > 0 &&
                          `Choose max ${menuTypeGroup.max_qty}`}
                      </label>
                      <span className="font-14 d-flex ps-3 text-danger">
                        {formik &&
                          formik.errors &&
                          Object.keys(formik.errors)?.length > 0 &&
                          formik.errors[menuTypeGroup.modifier_name]}
                      </span>
                      {temperatureMultipleOptionList.map((menuType, index) => {
                        const currentStepperMenuIcon =
                          stepperMenuIcon?.[
                            menuTypeGroup.modifier_name
                              .replace(/[^\w\s]/gi, "")
                              ?.split(" ")
                              ?.join("")
                              ?.split("")
                              ?.map((record) => record?.toLowerCase())
                              ?.join("")
                          ];
                        const currentMenuTypeIcon =
                          currentStepperMenuIcon?.find((record) =>
                            Array.isArray(record?.id)
                              ? record?.id?.includes(menuType?.citem_no)
                              : record?.id === menuType?.citem_no
                          );
                        const isMultipleActive = formik.values[
                          menuType.modifier_name
                        ]?.includes?.(menuType.citem_name);

                        return (
                          <>
                            <div className="d-flex justify-content-between mt-2">
                              <Form.Group
                                ref={nameRef}
                                className="pe-4"
                                key={index}>
                                <div className="d-flex flex-column gap-1">
                                  <div className="d-flex align-items-center gap-2">
                                    <ButtonOption
                                      id={index}
                                      value={menuType.citem_name}
                                      name={menuType.modifier_name}
                                      checked={isMultipleActive}
                                      disabled={
                                        menuTypeGroup.max_qty !== 0 &&
                                        formik.values[menuType.modifier_name]
                                          ?.length >= menuTypeGroup.max_qty &&
                                        !formik.values[
                                          menuType.modifier_name
                                        ].includes(menuType.citem_name)
                                      }
                                      onClick={(name, value, event) => {
                                        if (!isMultipleActive) {
                                          if (
                                            menuTypeGroup.max_qty !== 0 &&
                                            formik.values[
                                              menuType.modifier_name
                                            ]?.length >= menuTypeGroup.max_qty
                                          ) {
                                            event.preventDefault();
                                            return;
                                          }
                                        }

                                        if (!isMultipleActive) {
                                          formik.setFieldValue(name, [
                                            ...(formik.values?.[
                                              menuType.modifier_name
                                            ] || []),
                                            value,
                                          ]);
                                        } else {
                                          formik.setFieldValue(
                                            menuType.modifier_name,
                                            formik.values?.[
                                              menuType.modifier_name
                                            ]?.filter(
                                              (record) =>
                                                record !== menuType.citem_name
                                            )
                                          );
                                        }

                                        if (
                                          menuTypeGroup.max_qty !== 0 &&
                                          formik.values[menuType.modifier_name]
                                            ?.length +
                                            1 ===
                                            menuTypeGroup.max_qty
                                        ) {
                                          toggleComment?.(
                                            menuTypeGroup.item_menutype_grpdtls,
                                            "up"
                                          );
                                          toggleComment?.(
                                            menuTypeGroup.item_menutype_grpdtls +
                                              1,
                                            "down"
                                          );
                                        }
                                      }}
                                      iconOptions={{
                                        ...(currentMenuTypeIcon || {
                                          type: "svg",
                                        }),
                                        icon: isMultipleActive
                                          ? currentMenuTypeIcon?.activeIcon ||
                                            defaultIcon?.activeIcon
                                          : currentMenuTypeIcon?.originalIcon ||
                                            defaultIcon?.originalIcon,
                                      }}
                                    />
                                    <span className="item-name">
                                      {menuType.citem_name}
                                    </span>

                                    {/* <Form.Check
                              type="checkbox"
                              // id={`${menuType.modifier_name}`}
                              id={index}
                              name={`${menuType.modifier_name}`}
                              label={`${menuType.citem_name}`}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  if (
                                    menuTypeGroup.max_qty !== 0 &&
                                    formik.values[menuType.modifier_name]
                                      ?.length >= menuTypeGroup.max_qty
                                  ) {
                                    e.preventDefault();
                                    return;
                                  }
                                } else {
                                }

                                if (e.target.checked) {
                                  formik.handleChange(e);
                                } else {
                                  formik.setFieldValue(
                                    menuType.modifier_name,
                                    formik.values?.[
                                      menuType.modifier_name
                                    ]?.filter(
                                      (record) => record !== menuType.citem_name
                                    )
                                  );
                                }

                                if (
                                  menuTypeGroup.max_qty !== 0 &&
                                  formik.values[menuType.modifier_name]
                                    ?.length +
                                    1 ===
                                    menuTypeGroup.max_qty
                                ) {
                                  toggleComment(
                                    menuTypeGroup.item_menutype_grpdtls,
                                    "up"
                                  );
                                  toggleComment(
                                    menuTypeGroup.item_menutype_grpdtls + 1,
                                    "down"
                                  );
                                }
                              }}
                              value={menuType.citem_name}
                              checked={formik.values[
                                menuType.modifier_name
                              ].includes(menuType.citem_name)}
                              className="public-sans-font line-height-25 font-14 fw-bold black-text test-start text-uppercase"
                              disabled={
                                menuTypeGroup.max_qty !== 0 &&
                                formik.values[menuType.modifier_name]?.length >=
                                  menuTypeGroup.max_qty &&
                                !formik.values[menuType.modifier_name].includes(
                                  menuType.citem_name
                                )
                              }
                            /> */}
                                  </div>
                                  {breakpointQty &&
                                    formik.values?.[
                                      menuType?.modifier_name
                                    ]?.includes(menuType?.citem_name) && (
                                      <div
                                        className="left-mb-1"
                                        style={{
                                          marginLeft: 62,
                                        }}>
                                        <BsDashLg
                                          className="qty-class me-3"
                                          onClick={() =>
                                            decrementCounter(
                                              menuType?.modifier_name,
                                              menuType?.citem_name
                                            )
                                          }
                                        />
                                        <label className="cart-item-qty">
                                          {getQtyItem(
                                            menuType?.modifier_name,
                                            menuType?.citem_name
                                          )}
                                        </label>
                                        <BsPlusLg
                                          className={cx("qty-class ms-3", {
                                            "qty-class-disable":
                                              menuType.max_qty !== 0 &&
                                              getQtyItem(
                                                menuType?.modifier_name,
                                                menuType?.citem_name
                                              ) === menuType.max_qty,
                                          })}
                                          onClick={() => {
                                            if (
                                              menuType.max_qty !== 0 &&
                                              getQtyItem(
                                                menuType?.modifier_name,
                                                menuType?.citem_name
                                              ) === menuType.max_qty
                                            ) {
                                              return;
                                            }

                                            if (
                                              menuTypeGroup?.group_limit === 0
                                            ) {
                                              return incrementCounter(
                                                menuType?.modifier_name,
                                                menuType?.citem_name
                                              );
                                            }

                                            if (
                                              getQtyItem(
                                                menuType?.modifier_name,
                                                menuType?.citem_name
                                              ) +
                                                getGroupQty(
                                                  menuType?.modifier_name,
                                                  menuType?.citem_name
                                                ) <
                                              menuTypeGroup?.group_limit
                                            ) {
                                              return incrementCounter(
                                                menuType?.modifier_name,
                                                menuType?.citem_name
                                              );
                                            }
                                          }}
                                        />
                                      </div>
                                    )}
                                </div>
                              </Form.Group>
                              <span className="customize-item-citen-name pe-4 text-uppercase d-flex flex-row justify-content-between gap-2">
                                {!breakpointQty &&
                                  formik.values?.[
                                    menuType?.modifier_name
                                  ]?.includes(menuType?.citem_name) && (
                                    <div ref={qtyRef}>
                                      <BsDashLg
                                        className="qty-class me-3"
                                        onClick={() =>
                                          decrementCounter(
                                            menuType?.modifier_name,
                                            menuType?.citem_name
                                          )
                                        }
                                      />
                                      <label className="cart-item-qty">
                                        {getQtyItem(
                                          menuType?.modifier_name,
                                          menuType?.citem_name
                                        )}
                                      </label>
                                      <BsPlusLg
                                        className={cx("qty-class ms-3", {
                                          "qty-class-disable":
                                            menuType.max_qty !== 0 &&
                                            getQtyItem(
                                              menuType?.modifier_name,
                                              menuType?.citem_name
                                            ) === menuType.max_qty,
                                        })}
                                        onClick={() => {
                                          if (
                                            menuType.max_qty !== 0 &&
                                            getQtyItem(
                                              menuType?.modifier_name,
                                              menuType?.citem_name
                                            ) === menuType.max_qty
                                          ) {
                                            return;
                                          }

                                          if (
                                            menuTypeGroup?.group_limit === 0
                                          ) {
                                            return incrementCounter(
                                              menuType?.modifier_name,
                                              menuType?.citem_name
                                            );
                                          }

                                          if (
                                            getQtyItem(
                                              menuType?.modifier_name,
                                              menuType?.citem_name
                                            ) +
                                              getGroupQty(
                                                menuType?.modifier_name,
                                                menuType?.citem_name
                                              ) <
                                            menuTypeGroup?.group_limit
                                          ) {
                                            return incrementCounter(
                                              menuType?.modifier_name,
                                              menuType?.citem_name
                                            );
                                          }
                                        }}
                                      />
                                    </div>
                                  )}
                                <label
                                  style={{
                                    minWidth: 60,
                                  }}>
                                  {selectedDineOption === "takeaway" ? (
                                    <span className="public-sans-font line-height-25 font-14 fw-bold line-height-20 black-text text-uppercase">
                                      {menuType?.price_dtls[0]?.dine_in_price >
                                      0
                                        ? `+$${(menuType?.price_dtls[0]?.takeaway_price).toFixed(
                                            2
                                          )}`
                                        : ""}
                                    </span>
                                  ) : (
                                    <span className="public-sans-font line-height-25 font-14 fw-bold line-height-20 black-text text-uppercase">
                                      {menuType?.price_dtls[0]?.dine_in_price >
                                      0
                                        ? `+$${(menuType?.price_dtls[0]?.dine_in_price).toFixed(
                                            2
                                          )}`
                                        : ""}
                                    </span>
                                  )}
                                </label>
                              </span>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </>
                )}
          </>
        ) : null}
      </>
    )
  );
};

export default CustomizeItem;
