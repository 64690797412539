import React, { useEffect, useState } from "react";
import Header from "../../component/header";
import config from "../../config/config";
import moment from "moment";
import api from "../../api/api";
import { STORAGEKEY } from "../../constants";

const MyOrders = () => {
  const [myOrders, setMyOrders] = useState([]);

  const handleGetMyOrders = async () => {
    try {
      const { data } = await api.getWithParams("/GetTableQROrderDtls/01", {
        sessionid: localStorage.getItem(STORAGEKEY.SESSION_ID)
          ? localStorage.getItem(STORAGEKEY.SESSION_ID)
          : "",
        storename: localStorage.getItem(STORAGEKEY.STORE_NAME)
          ? localStorage.getItem(STORAGEKEY.STORE_NAME)
          : "",
        tableno: localStorage.getItem(STORAGEKEY.TABLE_NO)
          ? localStorage.getItem(STORAGEKEY.TABLE_NO)
          : "",
        trandate: moment().format("YYYY/MM/DD"),
      });

      const groups = data?.data?.[0]?.output?.[0]?.sales_dtls.reduce(
        (groups, order) => {
          const date = new Date(order?.item_kds_ready_datetime);

          date.setSeconds(0);

          if (!groups[date]) {
            groups[date] = [];
          }

          groups[date].push(order);
          return groups;
        },
        {}
      );

      if (groups) {
        const groupArrays = Object.keys(groups).map((date) => {
          return {
            date,
            orders: groups[date],
          };
        });

        setMyOrders(groupArrays);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    handleGetMyOrders();
  }, []);

  return (
    <>
      <div className="App">
        <div className="qr-before-body w-100">
          <Header title="My Orders" />
          <div
            className="inner-div main-div position-absolute w-100"
            style={{ maxHeight: "90vh" }}>
            <div>
              {myOrders?.length === 0 ? (
                <div>
                  <img
                    src={`/assets/commonImages/${config?.empty}.png`}
                    alt="empty Screen"
                    className="mt-5 pt-5"
                  />
                  <div className="d-grid mt-5">
                    <label className="no-history-text mb-1">No My Orders</label>
                    <label className="no-history-desc">
                      You don't have any orders yet
                    </label>
                  </div>
                </div>
              ) : (
                <>
                  <div>
                    <div className="d-flex ps-4 pt-3 pb-3">
                      <label className="last-order-text pe-3">
                        Your current orders
                      </label>
                    </div>
                    <div className="bg-white">
                      {myOrders?.map((orderData, index) => (
                        <div
                          key={index}
                          className="d-flex flex-column gap-1 div-border pt-3 pb-3 ps-3 pe-4">
                          <h5
                            className="mb-1"
                            style={{
                              width: "max-content",
                            }}>
                            {moment(orderData?.date).format(
                              "DD MMM YYYY HH:mm A"
                            )}
                          </h5>
                          {orderData?.orders?.map((record, idx) => (
                            <div key={`order-item-${idx}`} className="">
                              <div className="d-flex justify-content-between mb-2">
                                <label className="order-title text-start d-block overflow-hidden text-nowrap order-text">
                                  {idx + 1}. {record?.item_name}
                                </label>
                                <label className="order-date text-start">
                                  x {record?.qty}
                                </label>
                              </div>
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyOrders;
