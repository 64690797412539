import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import ButtonOption from "../../component/ButtonOption/ButtonOption";
import { isEmpty } from "lodash";

const CustomizeMinQtyOrder = ({
  index,
  menuType,
  handleRadioButtonSelected,
  menuTypeGroup,
  formik,
  selectedDineOption,
  isDefaultSelect,
  stepperMenuIcon,
  defaultIcon,
}) => {
  const currentStepperMenuIcon =
    stepperMenuIcon?.[
      menuTypeGroup.modifier_name
        .replace(/[^\w\s]/gi, "")
        ?.split(" ")
        ?.join("")
        ?.split("")
        ?.map((record) => record?.toLowerCase())
        ?.join("")
    ];
  const currentMenuTypeIcon = currentStepperMenuIcon?.find((record) =>
    Array.isArray(record?.id)
      ? record?.id?.includes(menuType?.citem_no)
      : record?.id === menuType?.citem_no
  );

  const isSingleActive =
    formik.values[menuType.modifier_name] === `${menuType.citem_name}`
      ? true
      : false;
  const isMultipleActive =
    formik.values?.[menuTypeGroup?.modifier_name] === menuType.citem_name;

  useEffect(() => {
    if (isDefaultSelect && isEmpty(formik.values[menuType.modifier_name])) {
      formik.setFieldValue(menuType.modifier_name, menuType.citem_name);
    }
  }, [isDefaultSelect]);

  return (
    <div className="d-flex justify-content-between mt-2">
      <Form.Group className="pe-4 me-4" key={index}>
        <div>
          {menuTypeGroup?.is_optional === "Y" && menuTypeGroup?.max_qty > 1 ? (
            <div className="d-flex align-items-center gap-2">
              <ButtonOption
                id={index}
                value={menuType.citem_name}
                name={menuType.modifier_name}
                checked={isMultipleActive}
                onClick={(name, value, event) => {
                  if (
                    menuTypeGroup?.is_optional === "Y" &&
                    formik.values?.[menuTypeGroup?.modifier_name] ===
                      menuType.citem_name
                  ) {
                    formik?.setFieldValue(menuTypeGroup?.modifier_name, []);
                    return;
                  }

                  if (formik.values[menuType.modifier_name]?.length === 1) {
                    event.preventDefault();
                    return;
                  }

                  formik?.setFieldValue(
                    menuTypeGroup?.modifier_name,
                    menuType.citem_name
                  );
                  handleRadioButtonSelected(
                    name,
                    value,
                    menuType.citem_name,
                    menuTypeGroup.item_menutype_grpdtls,
                    true
                  );
                }}
                iconOptions={{
                  ...(currentMenuTypeIcon || {
                    type: "svg",
                  }),
                  icon: isMultipleActive
                    ? currentMenuTypeIcon?.activeIcon || defaultIcon?.activeIcon
                    : currentMenuTypeIcon?.originalIcon ||
                      defaultIcon?.originalIcon,
                }}
              />
              <span className="item-name">{menuType.citem_name}</span>
            </div>
          ) : (
            <div className="d-flex align-items-center gap-2">
              <ButtonOption
                id={index}
                value={menuType.citem_name}
                name={menuType.modifier_name}
                checked={isSingleActive}
                onClick={(name, value) =>
                  handleRadioButtonSelected(
                    name,
                    value,
                    menuTypeGroup.item_menutype_grpdtls,
                    false,
                    menuTypeGroup
                  )
                }
                iconOptions={{
                  ...(currentMenuTypeIcon || {
                    type: "svg",
                  }),
                  icon: isMultipleActive
                    ? currentMenuTypeIcon?.activeIcon || defaultIcon?.activeIcon
                    : currentMenuTypeIcon?.originalIcon ||
                      defaultIcon?.originalIcon,
                }}
              />
              <span className="item-name">{menuType.citem_name}</span>
            </div>
          )}
        </div>
      </Form.Group>
      <label>
        {selectedDineOption === "takeaway" ? (
          <span className="public-sans-font line-height-25 font-14 fw-bold line-height-20 black-text position-absolute end-0 pe-4 text-uppercase">
            {menuType?.price_dtls[0]?.dine_in_price > 0
              ? `+$${(menuType?.price_dtls[0]?.takeaway_price).toFixed(2)}`
              : ""}
          </span>
        ) : (
          <span className="public-sans-font line-height-25 font-14 fw-bold line-height-20 black-text position-absolute end-0 pe-4 text-uppercase">
            {menuType?.price_dtls[0]?.dine_in_price > 0
              ? `+$${(menuType?.price_dtls[0]?.dine_in_price).toFixed(2)}`
              : ""}
          </span>
        )}
      </label>
    </div>
  );
};

export default CustomizeMinQtyOrder;
