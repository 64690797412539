import { Fragment, useCallback, useMemo, useRef, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import {
  BsFillCaretUpFill,
  BsFillCaretDownFill,
  BsPlus,
  BsFillCheckCircleFill,
} from "react-icons/bs";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, updateToCart } from "../../redux/cartSlice";
import { useNavigate } from "react-router-dom";
import get from "lodash/get";
import { formatCents } from "../../utils/numberUtils";
import { STORAGEKEY } from "../../constants";
import _, { isArray } from "lodash";
import useBrandConfig from "../../hooks/useBrandConfig";
import Icon from "../../component/Icon/Icon";
import usePromotion from "../../hooks/usePromotion";
import CustomizeForm from "../../component/CustomizeForm/CustomizeForm";
import { setOpenConfetti } from "../../redux/appSlice";

export const CustomizeModal = ({
  showModal,
  setShowModal,
  showModalData: customizeData,
  onSuccessSubmit,
  redirectAfterSubmit = true,
}) => {
  const dispatcher = useDispatch();
  const navigate = useNavigate();

  const cartData = useSelector((state) => state.cart.cart);

  const { config, iconConfig } = useBrandConfig();
  const { handleConvertPromotionItem } = usePromotion();

  const stepperMenuIcon = iconConfig?.stepper_menu;
  const defaultIcon = iconConfig?.default_icon;

  const selectedDineOption = localStorage.getItem(STORAGEKEY.DINING_MODE);

  /**
   * * GET selected dine option
   */
  const isDineIn = useMemo(
    () => selectedDineOption === "dinein",
    [selectedDineOption]
  );

  const [menuTypeGroupIndex, setMenuTypeGroupIndex] = useState(1);

  const quantity = useRef(customizeData.qty ? customizeData.qty : 1);

  const validModifierCheck = (value, valueItem = []) => {
    let maxAvlQty = customizeData.item?.itemmaster_menutype_grpdtls.filter(
      (item) => value.modifier_name === item.modifier_name
    )[0].max_qty;

    let selectedQty = 0;
    valueItem?.forEach((item) => {
      selectedQty += customizeData.item?.itemmaster_menutypedtls.filter(
        (modItem) => modItem.citem_name === item
      )[0].qty;
    });

    return maxAvlQty === selectedQty;
  };

  /**
   * * Group list by {field}
   *
   * @param {*} addOns
   * @param {*} field
   * @returns
   */
  const groupListByField = (addOns, field) =>
    addOns?.reduce(function (a, e) {
      // GROUP BY estimated key (estKey), well, may be a just plain key
      // a -- Accumulator result object
      // e -- sequentally checked Element, the Element that is tested just at this itaration

      // new grouping name may be calculated, but must be based on real value of real field
      let estKey = e[field];

      (a[estKey] ? a[estKey] : (a[estKey] = null || [])).push(e);
      return a;
    }, {});

  /**
   * * Generate initial formik values
   */
  const initialValues = useMemo(() => {
    const initVal = {};

    get(customizeData, "item.itemmaster_menutype_grpdtls", []).forEach((el) => {
      if (el.max_qty === 1) {
        const originalVal = customizeData?.addOnValues
          ? customizeData?.addOnValues.filter(
              (addOn) => addOn?.modifier_name === el?.modifier_name
            )?.[0]
          : customizeData?.item?.itemmaster_menutypedtls?.filter(
              (customItems) => customItems?.modifier_name === el?.modifier_name
            )?.[0];
        const val = originalVal?.citem_name;

        if (originalVal?.is_default !== "N") {
          initVal[el?.modifier_name] = val;
        }
      } else {
        if (groupListByField(customizeData?.addOnValues, "modifier_name")) {
          // * Checkbox
          initVal[el.modifier_name] = groupListByField(
            customizeData?.addOnValues,
            "modifier_name"
          )
            [customizeData?.modifier_name]?.map((it) =>
              Array.from(Array(it?.modifier_qty), (_, x) => x)
                .flat(Infinity)
                .map((o) => it?.citem_name)
            )
            .flat(Infinity);
        } else {
          const originalValArray =
            customizeData?.item?.itemmaster_menutypedtls?.filter(
              (customItems) => customItems?.modifier_name === el?.modifier_name
            );
          const defaultValueArray = [];

          originalValArray?.forEach((record) => {
            if (record?.is_default === "Y") {
              defaultValueArray.push(record?.citem_name);
            }
          });

          if (defaultValueArray?.length > 0) {
            initVal[el?.modifier_name] = defaultValueArray;
          }
        }
      }
    });

    return initVal;
  }, [customizeData]);

  /**
   * * Generate formik validation schema
   */
  const validationSchema = useMemo(() => {
    const schema = {};
    get(customizeData, "item.itemmaster_menutype_grpdtls", []).forEach((el) => {
      if (el?.is_optional !== "Y") {
        if (el.max_qty === 1) {
          schema[el.modifier_name] = Yup.string().required(
            `${el.modifier_name} is required`
          );
        } else if (el.max_qty > 0) {
          schema[el.modifier_name] = Yup.array().test(
            "maxQtyTest",
            `Please select ${el.max_qty} ${el.modifier_name}`,
            (valueItem) => validModifierCheck(el, valueItem)
          );
        } else {
          schema[el.modifier_name] = Yup.array().nullable().notRequired();
        }
      }
    });

    return schema;
  }, [customizeData, validModifierCheck]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object(validationSchema),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      let addOnValues = [];
      formik.validateForm();
      if (Object.keys(values) && Object.keys(values)?.length > 0) {
        for (const addOn of Object.keys(values)) {
          customizeData.item?.itemmaster_menutypedtls
            .filter(
              (item) =>
                item.modifier_name === addOn &&
                (isArray(values[addOn])
                  ? values[addOn]?.includes(item.citem_name)
                  : values[addOn] === item.citem_name)
            )
            .map((addOnData) => {
              addOnValues.push({
                item_no: addOnData.citem_no,
                modifier_name: addOnData.modifier_name,
                modifier_qty: isArray(values[addOn])
                  ? values[addOn].filter(
                      (addonName) => addonName === addOnData.citem_name
                    ).length
                  : 1,
                citem_name: addOnData.citem_name,
                price_dtls: isDineIn
                  ? addOnData.price_dtls[0].dine_in_price
                  : addOnData.price_dtls[0].takeaway_price,
                sku_no: addOnData.citem_no.replace("PRD", "SKU"),
                uom: addOnData.uom,
              });
            });
        }
      }

      const itemInCart = cartData.find(
        (item) =>
          item.id === customizeData.item.item_no &&
          item.addOnValues.length === 0 &&
          _.isEqual(
            _.map(item.addOnValues, "item_no"),
            _.map(addOnValues, "item_no")
          )
      );

      // return;
      if (customizeData.qty) {
        dispatcher(
          updateToCart({
            id: customizeData.item.item_no,
            uuid: customizeData.uuid,
            addOnValues: addOnValues,
            item: customizeData.item,
            qty: quantity.current,
            price: price.original,
            remarks: values.remarks,
          })
        );
      } else {
        dispatcher(
          addToCart(
            handleConvertPromotionItem(
              {
                uuid: itemInCart?.uuid,
                id: customizeData.item.item_no,
                addOnValues: addOnValues,
                item: customizeData.item,
                qty: quantity.current,
                price: price.original,
                remarks: values.remarks,
              },
              true
            )
          )
        );
      }
      if (config?.enableConfetti) {
        dispatcher(setOpenConfetti(true));
      }
      setShowModal(false);
      if (onSuccessSubmit) {
        onSuccessSubmit(customizeData.item);
      }
      if (redirectAfterSubmit) {
        navigate("/home", {
          state: {
            addOnValues: addOnValues,
            item: customizeData.item,
            qty: quantity.current,
            price: price.original,
            remarks: values.remarks,
          },
        });
      }
    },
  });

  const toggleComment = (id, values) => {
    if (values === "up") {
      setMenuTypeGroupIndex((prev) => (prev.commentShown === id ? id : ""));
    } else {
      setMenuTypeGroupIndex((prev) => (prev.commentShown !== id ? id : ""));
    }
  };

  const getQtyByModifier = (modifierName) => {
    return typeof formik.values[modifierName] === "string"
      ? 1
      : formik.values[modifierName]?.length;
  };

  /**
   * * GET grouped menu type list
   */
  const getMenuTypeGrouped = useCallback((data) => {
    return get(data, "item.itemmaster_menutype_grpdtls", [])
      .slice()
      .sort((a, b) => a.item_menutype_grpdtls - b.item_menutype_grpdtls)
      .map((menuTypeGroup, i) => menuTypeGroup);
  }, []);

  /**
   * * GET menu type list
   */
  const getMenuType = useCallback((data, modifierName) => {
    return get(data, "item.itemmaster_menutypedtls", []).filter(
      (customItems) => customItems.modifier_name === modifierName
    );
  }, []);

  /**
   *  * GET selected items
   */
  const selectedItems = useMemo(() => {
    const selectedItems = [];
    Object.keys(formik.values).map((item) => {
      if (typeof formik.values[item] === "string") {
        selectedItems.push(
          ...customizeData?.item?.itemmaster_menutypedtls.filter(
            (it) =>
              it.modifier_name === item && it.citem_name === formik.values[item]
          )
        );
      } else if (typeof formik.values[item] === "object") {
        formik.values[item].map((it) => {
          selectedItems.push(
            ...customizeData.item?.itemmaster_menutypedtls?.filter(
              (record) =>
                record?.citem_name === it && record?.modifier_name === item
            )
          );
        });
      }
    });

    return selectedItems;
  }, [customizeData, formik?.values]);

  /**
   *  * GET total price
   */
  const price = useMemo(() => {
    const price = selectedItems.reduce((total, item) => {
      return (
        total +
        item.price_dtls[0][isDineIn ? "dine_in_price" : "takeaway_price"] *
          item.qty
      );
    }, 0);

    return {
      original: price, // original price number
      formatted: formatCents(price), // Formatted  to display
    };
  }, [selectedItems]);

  const handleRadioButtonSelected = (
    name,
    value,
    currAccordionId,
    customChange,
    menuTypeGroup
  ) => {
    if (name === getMenuTypeGrouped(customizeData)?.[0]?.modifier_name) {
      formik?.setFieldValue(
        "TEMPERATURE",
        getMenuTypeGrouped(customizeData)?.find(
          (record) => record?.modifier_name === "TEMPERATURE"
        )?.max_qty !== 1
          ? []
          : ""
      );
    }

    if (menuTypeGroup?.is_optional === "Y" && formik.values?.[name] === value) {
      formik.setFieldValue(name, "");
    } else if (!customChange) {
      formik.setFieldValue(name, value);
    }

    toggleComment(currAccordionId, "up");
    toggleComment(currAccordionId + 1, "down");
  };

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      centered
      className="customize-content">
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton className="modal-border" closeVariant="white">
          <Modal.Title>
            <div className="d-grid">
              <label className="customize-header">
                {customizeData.item.brand_name}
              </label>
              <label className="customize-header">S{price.formatted}</label>
            </div>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <CustomizeForm
            price={price}
            getMenuTypeGrouped={getMenuTypeGrouped}
            customizeData={customizeData}
            formik={formik}
            getQtyByModifier={getQtyByModifier}
            menuTypeGroupIndex={menuTypeGroupIndex}
            toggleComment={toggleComment}
            getMenuType={getMenuType}
            handleRadioButtonSelected={handleRadioButtonSelected}
            stepperMenuIcon={stepperMenuIcon}
            defaultIcon={defaultIcon}
            usingPriceHeader
            selectedDineOption={selectedDineOption}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="fill-cancel-btn w-100 fill-green-btn cart-btn text-uppercase fw-bold"
            type="submit"
            disabled={!formik.isValid}>
            Add
            <BsPlus style={{ fontSize: "25px", color: "white" }} />
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
