import React, { useEffect } from "react";
import { getBrandConfig } from "../services/supabase/brandConfig";
import { useDispatch, useSelector } from "react-redux";
import {
  setConfig,
  setIconConfig,
  setRecommendations,
  setStoreClosed,
  setWelcomeBanner,
} from "../redux/appSlice";
import { storeConfig } from "../services/tableQRServices";
import { STORAGEKEY } from "../constants";
import moment from "moment";

const useBrandConfig = () => {
  const dispatch = useDispatch();
  const { config, welcomeBanner, iconConfig, recommendations } = useSelector(
    (state) => state.app
  );

  const sessionId = localStorage.getItem(STORAGEKEY.SESSION_ID);

  const handleGetConfig = async () => {
    const { data } = await getBrandConfig();

    dispatch(setConfig(data?.config));
    dispatch(setWelcomeBanner(data?.welcomeBanner));
    dispatch(setIconConfig(data?.iconConfig));
    dispatch(setRecommendations(data?.recommendations || []));
  };

  const handleCheckTimeSlotStoreClosed = async () => {
    try {
      const { data } = await storeConfig();

      const response = data?.data?.[0]?.output?.[0];

      const currentDay = moment().format("ddd").toLowerCase();

      const findCurrentTimeSlotEnable = response?.[`${currentDay}_enable`];
      const findCurrentTimeSlotStartTime = response?.[`${currentDay}_st_time`];
      const findCurrentTimeSlotEndTime = response?.[`${currentDay}_ed_time`];

      if (findCurrentTimeSlotEnable) {
        if (
          findCurrentTimeSlotStartTime > moment().format("HH:mm:ss") ||
          findCurrentTimeSlotEndTime < moment().format("HH:mm:ss")
        ) {
          dispatch(setStoreClosed(true));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCheckStoreClosed = () => {
    if (!!Object.keys(config || {}).length) {
      if (config?.storeClosed) {
        dispatch(setStoreClosed(true));
      } else if (sessionId) {
        handleCheckTimeSlotStoreClosed();
      } else {
        dispatch(setStoreClosed(false));
      }
    }
  };

  // useEffect(() => {
  //   handleGetConfig();
  // }, []);

  return {
    config,
    welcomeBanner,
    iconConfig,
    recommendations,
    handleCheckStoreClosed,
    handleGetConfig,
  };
};

export default useBrandConfig;
