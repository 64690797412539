import React, { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import useBrandConfig from "../../hooks/useBrandConfig";

const PaymentOptions = () => {
  const salesNoData = useSelector((state) => state.cart.sales_no);
  const navigate = useNavigate();
  const { state } = useLocation();

  const { config } = useBrandConfig();

  const handleViewHome = () => navigate("/home");

  const wording = useMemo(() => {
    const translatedWording = {
      Counter: {
        title: `Receipt No: ${salesNoData}`,
        descriptions: (
          <>
            <label className="p-2 mt-2 thank-desc text-center">
              Please proceed to the counter to confirm your order and make the
              payment.
            </label>
            <label className="p-2 thank-desc text-center">Thank you!</label>
          </>
        ),
      },
      QlubQR: {
        title: "Pay at QlubQR",
        descriptions: (
          <div>
            To expedite your payment process with QlubQR, please follow these
            simple steps:
            <ol>
              <li>Scan the QR code conveniently placed on your table.</li>
              <li>Enter the payment amount and confirm the transaction.</li>
              <li>
                That's it! Your payment will be processed swiftly and securely.
              </li>
            </ol>
            <div>Thank you!</div>
          </div>
        ),
      },
    };

    return translatedWording[state.pm];
  }, [salesNoData, state.pm]);

  return (
    <>
      <div className="App">
        <div className="qr-before-body w-100">
          <div className="inner-div main-div position-absolute w-100">
            <div className="pt-5 mt-4">
              <img
                src={`/assets/commonImages/${config?.flower}.svg`}
                alt=""
                className="mt-5"
                height={200}
              />
            </div>
            <div className="d-grid me-4 ms-4">
              <label className="thank-text mt-4 text-center">
                {wording?.title}
              </label>
              {wording?.descriptions}
            </div>
            <Button
              className="thank-btn mt-4 me-4 ms-4"
              type="submit"
              onClick={handleViewHome}>
              Go back to Menu
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentOptions;
